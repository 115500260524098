import { useFlashBarItems } from "common/UseFlashBarItems/useFlashBarItems";
import useInput from "hooks/useInput";
import { CatalogItemValues } from "interfaces/catalogSchema";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CatalogicalServiceV2 } from "services/catalogical-service-v2";

const useEditCatalogForm = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const decodedId = decodeURIComponent(id || "");
    const { formValues: catalogItemValues, handleInputChange, setFormValuesBulk: setCatalogItemValuesBulk } = useInput<CatalogItemValues>({
        //Taxonomy Form Values
        subtype: "",
        manufacturer: "",
        short_name: "",
        sku: "",

        //Product Information Form Values
        name: "",
        amount: "",
        os: [],
        order_redirect_url: "",
        short_description: "",
        description: "",

        //Inventory Information Form Values
        product_download_url: "",
        restock_threshold: "",
        retail_price: "",
        license_activation_instructions: "",
        quote_number: "",
        quote_date: "",
        currency: "",
        status: "",

        //Product Categories Form Values
        categories: [],

        //Available Regions Form Values
        region_restrictions: [],
    });
    const [submitStatus, setSubmitStatus] = useState<{ loading: boolean; error: string | null }>({
        loading: false,
        error: null,
    });
    const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
    const { flashBarItems, actions } = useFlashBarItems();
    const [isLoading, setIsLoading] = useState(false);

    /* istanbul ignore next */
    const handleCancelClick = () => {
        setIsLeaveModalVisible(true);
    };

    /* istanbul ignore next */
    const handleDismissModal = () => {
        setIsLeaveModalVisible(false);
    };

    /* istanbul ignore next */
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    /* istanbul ignore next */
    useEffect(() => {
        const fetchItemData = async () => {
            setIsLoading(true);
            try {
                const data = await CatalogicalServiceV2.instance.getCatalogItemByTaxonomyId(decodedId);
                const regionRestrictions =
                    data.item.availability_restrictions?.find((restriction) => restriction.level === "region")
                        ?.values || [];
                // Populate formValues with fetched data
                setCatalogItemValuesBulk({
                    name: data.item.name,
                    subtype: data.item.subtype,
                    manufacturer: data.item.manufacturer,
                    short_name: data.item.short_name,
                    sku: data.item.sku,
                    amount: data.item.price?.amount.toString() || "",
                    currency: data.item.price?.currency || "",
                    os: data.item.os ?? [],
                    short_description: data.item.short_description || "",
                    order_redirect_url: data.item.references?.order_redirect_url || "",
                    description: data.item.description || "",
                    status: data.item.status || "",
                    product_download_url: data.item.references?.product_download_url || "",
                    restock_threshold: data.item.restock_threshold?.toString() || "",
                    retail_price: data.item.retail_price?.amount.toString() || "",
                    license_activation_instructions: data.item.license_activation_instructions || "",
                    categories: data.item.categories || [],
                    region_restrictions: regionRestrictions,
                });
            } catch (error) {
                actions.addErrorFlashBarItem("Error fetching data");
            }
            setIsLoading(false);
        };

        fetchItemData();
    }, [decodedId]);

    // State to track errors in required fields
    const [requiredFieldsErrors, setRequiredFieldsErrors] = useState({
        nameError: "",
        subtypeError: "",
        manufacturerError: "",
        shortNameError: "",
        skuError: "",
        amountError: "",
        retail_priceError: "",
        restock_thresholdError: "",
    });

    // method to encapsulate validation logic
    const validateRequiredFields = () => {
        const errors = {
            subtypeError: catalogItemValues.subtype ? "" : "Subtype is a required field",
            manufacturerError: catalogItemValues.manufacturer ? "" : "Vendor is a required field",
            shortNameError: catalogItemValues.short_name ? "" : "Short Name is a required field",
            skuError: catalogItemValues.sku ? "" : "SKU is a required field",
            nameError: catalogItemValues.name ? "" : "Product Name is a required field",
            amountError: catalogItemValues.amount ? "" : "Cost is a required field",
            retail_priceError: catalogItemValues.retail_price ? "" : "Retail Price is a required field",
            restock_thresholdError: catalogItemValues.restock_threshold ? "" : "Restock Threshold is a required field",
        };
        return errors;
    };

    // validateForm method that uses validateRequiredFields
    const validateForm = () => {
        const newErrors = validateRequiredFields();
        // Updating the state with new error messages
        setRequiredFieldsErrors(newErrors);
        return Object.values(newErrors).every((error) => error === "");
    };

    // Function to handle the submission of the catalog update form
    /* istanbul ignore next */
    const handleEditCatalogButton = async () => {
        // Validate form before submission
        if (!validateForm()) {
            scrollToTop();
            return;
        }

        setSubmitStatus({ loading: true, error: null });
        try {
            // Attempting to update catalog item
            const response = await CatalogicalServiceV2.instance.editCatalogItem(catalogItemValues);
            navigate("/catalog", {
                replace: true,
                state: { flashMessage: `${catalogItemValues.name} successfully updated!` },
            });
        } catch (error) {
            // On error, show error message and update submission status
            const errorMessage = (error as Error).message;
            actions.addErrorFlashBarItem(errorMessage);
            setSubmitStatus({ loading: false, error: errorMessage });
            scrollToTop();
        }
    };

    return {
        catalogItemValues,
        handleInputChange,
        requiredFieldsErrors,
        handleEditCatalogButton,
        submitStatus,
        flashBarItems,
        handleCancelClick,
        isLeaveModalVisible,
        handleDismissModal,
        isLoading,
        validateForm,
    };
};

export default useEditCatalogForm;
