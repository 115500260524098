import {
    Box,
    SpaceBetween,
    Spinner
} from '@amzn/awsui-components-react';

import { AttachmentFile } from 'common';
import { Attachment } from '@amzn/ito-client';

interface OrderAttachmentsProps {
    attachments?: Attachment[],
    error: Error | undefined
}

export const OrderAttachments = (props: OrderAttachmentsProps) => {
    
    const displayAttachments = (): JSX.Element | JSX.Element[] => {
        if (!props.attachments || props.attachments.length === 0) {
            return <Box color="text-status-inactive">No attachments</Box>
        }
        
        const attachments = props.attachments.map((attachment, id) => (
            <AttachmentFile key={id} attachment={attachment} />
        ));
        return attachments;
    }

    return (
        <SpaceBetween size="m" direction="horizontal">
            {displayAttachments()}
        </SpaceBetween>
    )
};

