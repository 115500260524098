import { Box, Button, Flashbar, Form, Header, Link, SpaceBetween } from "@amzn/awsui-components-react";
import { getString } from "common/ui-string-labels/ui-strings-utils";

import AvailableRegionsForm from "./AvailableRegionsForm/AvailableRegionsForm";
import LeaveCreateCatalogModal from "./LeaveCreateCatalogModal/LeaveCreateCatalogModal";
import ProductInformationForm from "./ProductInformationForm/ProductInformationForm";
import TaxonomyForm from "./TaxonomyForm/TaxonomyForm";
import useCreateCatalogForm from "./useCreateCatalogForm";

const CreateCatalogForm = () => {
    const {
        catalogItemValues,
        handleInputChange,
        requiredFieldsErrors,
        handleCreateCatalogButton,
        submitStatus,
        handleCancelClick,
        isLeaveModalVisible,
        handleDismissModal,
        flashBarItems,
    } = useCreateCatalogForm();

    return (
        /* istanbul ignore next */
        <Form
            data-testid="create-catalog-form"
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={handleCancelClick}>
                        {getString("createCatalog.formLabels.cancelFormButton")}
                    </Button>

                    <Button
                        data-cy="create-catalog-submit-button"
                        variant="primary"
                        onClick={handleCreateCatalogButton}
                        loading={submitStatus.loading}
                    >
                        {getString("createCatalog.formLabels.createCatalogButton")}
                    </Button>
                </SpaceBetween>
            }
            header={
                <SpaceBetween size="m">
                    <Flashbar items={flashBarItems} />
                    <Header variant="h1" info={<Link>{getString("createCatalog.formLabels.formHeaderInfo")}</Link>}>
                        {getString("createCatalog.formLabels.formHeader")}
                    </Header>
                    <Box color="text-status-inactive">{getString("createCatalog.formLabels.formDescription")}</Box>
                </SpaceBetween>
            }
        >
            <SpaceBetween size="xl">
                <ProductInformationForm
                    catalogItemValues={catalogItemValues}
                    handleInputChange={handleInputChange}
                    errors={requiredFieldsErrors}
                />
                <TaxonomyForm
                    isEditMode={false}
                    catalogItemValues={catalogItemValues}
                    handleInputChange={handleInputChange}
                    errors={requiredFieldsErrors}
                />
                <AvailableRegionsForm
                    handleInputChange={handleInputChange}
                    selectedRegions={catalogItemValues.region_restrictions}
                />
            </SpaceBetween>
            <LeaveCreateCatalogModal visible={isLeaveModalVisible} onDismiss={handleDismissModal} />
        </Form>
    );
};
export default CreateCatalogForm;
