import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';

import ManageCatalogTable from '../../components/ManageCatalogTable/ManageCatalogTable';
import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from '../../common/navigation/SideNavigationMenu';

import Paths from "../../common/paths/paths";

const breadcrumbs = {
    items:
    [
      { text: Paths.Admin.name, href: Paths.Admin.url },
      { text: Paths.manageCatalog.name, href: Paths.manageCatalog.url }

    ]
  }
  
  const ManageCatalogPage = () => {
    const [navigationOpen, setNavigationOpen] = useState(false);
  
    return (
      <AppLayout
        content={<ManageCatalogTable />}        
        breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
        navigation={<SideNavigationMenu />}
        navigationOpen={navigationOpen}
        onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
      />
    );
  }
  
  export default ManageCatalogPage;