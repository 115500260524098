import {
  Container,
  Header,
  Spinner,
  Table
} from '@amzn/awsui-components-react';
import { ColumnSetting } from 'common/utils/config-models';
import { FunctionComponent, useEffect, useState } from 'react';

import ordersTableConfig from './order-sublineitems.table-config.json';
import { OrderSubLineItem, UpdateSubLineItemCommandInput } from '@amzn/ito-client';
import { buildColumnDefinitions, getString, validateRequiredField } from 'common';
import EmptyTableBox from 'common/EmptyTableBox/EmptyTableBox';
import { setNestedProperty } from '../../OrderSummaryTab/useOrderSummaryForm';
import { CSRFService } from 'services/csrf-service';
import { useUpdateSubLineItemDetails } from 'hooks';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { useOrderEditActions, useOrderEditItems } from 'views/OrdersDetailsPage/useOrderEdit';
const columns: ColumnSetting[] = ordersTableConfig.columns;

export interface OrdersSubLineItemsTableProps {
  useOrderEditItems: useOrderEditItems,
  useOrderEditActions: useOrderEditActions,
  reloadOrder: (id: string | undefined) => void,
  flashBarItemsActions: useFlashBarItemsActions,
}

export const OrderSubLineItemsTable: FunctionComponent<OrdersSubLineItemsTableProps> = (props: OrdersSubLineItemsTableProps) => {
  // Items
  const orderSelectedId = props.useOrderEditItems.orderSelected?.orderId;
  const lineItemSelected = props.useOrderEditItems.lastLineItemSelected;

  // Actions
  const [loading,] = useState(false);
  const currentColumnDefinitions = buildColumnDefinitions(columns, undefined, props.useOrderEditItems.canBeEdited);
  const { data, error, doUpdateSubLineItemAsync } = useUpdateSubLineItemDetails();

  useEffect(() => {
    if (data && !error) {
      // If sub-line item edit was successful, flag the order
      props.useOrderEditActions.flagOrderWithFirstEdit();

      props.reloadOrder(orderSelectedId);
    }
    else if (error) {
      props.flashBarItemsActions.addErrorFlashBarItem(`Error updating the item. ${error.message}`);
    }
  }, [data, error]);

  const handleSubmit = async (currentItem: OrderSubLineItem, columnDefinition: any, newValue: any) => {
    const itemUpdated = currentItem;

    // Validate the new value
    const errorMessage = columnDefinition.editConfig?.validation ? columnDefinition.editConfig.validation(itemUpdated, newValue) : undefined;
    if (errorMessage) {
      props.flashBarItemsActions.addErrorFlashBarItem(`Error updating the item. The field attempted to update is required`);
      return;
    }

    // Remove "$.", "$.name" --> "name";
    const fieldToUpdate = columnDefinition.selector.slice(2) as keyof OrderSubLineItem;
    
    // Handle special formats (non strings)
    if (fieldToUpdate === "quantityDelivered") {
      newValue = Number(newValue);
    }

    setNestedProperty(itemUpdated, fieldToUpdate, newValue);
    const updateProps: UpdateSubLineItemCommandInput = {
      orderId: orderSelectedId,
      itemId: lineItemSelected?.itemId,
      id: currentItem.id,
      subLineItem: itemUpdated
    };

    const csrfService = new CSRFService();
    const token = await csrfService.getToken();
    await doUpdateSubLineItemAsync(updateProps, token);
  }

  if (loading) {
    return (
      <Container>
        <Header variant="h2" data-testid="download-loading">
          {getString('manageOrders.table.downloadloading')}
        </Header>
        <Spinner size="large" />
      </Container>
    );
  }

  return (
    <Table
      data-testid="order-sub-line-items-table"
      empty={<EmptyTableBox />}
      header={
        <Header>{getString('orderDetails.tabs.itemsRequested.table.SublineItemsTableHeader')}</Header>
      }
      columnDefinitions={currentColumnDefinitions}
      loadingText={getString('common.loading')}
      items={lineItemSelected?.subLineItems!}
      resizableColumns={true}
      wrapLines
      submitEdit={handleSubmit}
    />
  );
};
