import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { useLocation, useNavigate } from 'react-router-dom';

import Paths from '../paths/paths';

const header = { text: 'IT Hardware admin', href: Paths.Admin.url };
const items: SideNavigationProps.Item[] = [
    {
        type: 'section',
        text: 'Orders',
        items: [ 
            { type: 'link', text: Paths.manageOrders.name, href: Paths.manageOrders.url },
        ]
    },
    {
        type: 'section',
        text: 'Catalog',
        items: [
            { type: 'link', text: Paths.manageCatalog.name, href: Paths.manageCatalog.url },
        ]
    },
    { type: "divider" },
    { type: 'link', text: Paths.exports.name, href: Paths.exports.url },
    { type: "divider" },
];

const SideNavigationMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * Prevents reloading the page and lets react router handle the redirect
     * @param event
     */
    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        if (!event.detail.external){
            event.preventDefault();
            if (event.detail.href) {
                navigate(event.detail.href);
            }
        }
    };

    return (
        <SideNavigation
            data-testid={"side-nav"}
            header={header}
            items={items}
            activeHref={location.pathname}
            onFollow={onFollowHandler}
        />
    );
}

export default SideNavigationMenu;