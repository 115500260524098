import moment from "moment";

export function getTimestampString(value: any): string {
    if (value) {
        return moment(value as string).fromNow();
    } else {
        return 'N/A';
    }
}

// Dates
export function buildDate(value: any) {
    if (value) {
        const timeAgo = moment(value as string).fromNow();
        return <span title={value}>{timeAgo}</span>;
    }
}

export function buildDateOnly(value: any) {
    if (value) {
        const date = moment(value as string).format('MM/DD/YYYY');
        return <span title={value}>{date.toString()}</span>;
    }
}

export function buildDateTimeLocal(value: any) {
    if (value) {
        const local = moment(value as string)
            .local()
            .format('MM/DD/YYYY HH:mm:ss');
        return <span title={value}>{local}</span>;
    }
    return <div>-</div>;
}
