import './ManageOrdersTable.scss';

import { Multiselect, MultiselectProps } from '@amzn/awsui-components-react/polaris';
import {
  Box,
  CollectionPreferences,
  Container,
  Grid,
  Header,
  PropertyFilter,
  Pagination,
  PaginationProps, 
  Spinner,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { Order } from '@amzn/ito-client';
import { ColumnSetting } from 'common/utils/config-models';
import { FunctionComponent, useEffect, useState } from 'react';
import EmptyTableBox from '../../common/EmptyTableBox/EmptyTableBox';
import { getPropertyFilterString, getString } from '../../common/ui-string-labels/ui-strings-utils';
import {
  SortingState,
  buildColumnDefinitions,
  buildFilteringProperties,
  buildMultiSelectLocationOptions,
  buildSelectOptionsFromStrings,
  buildVisibleContentOptions, 
  convertTokensToPropertyFilter, 
  defaultPagingOptions
} from '../../common/utils';
import { useGetOrders } from '../../hooks/orders';

import './ManageOrdersTable.scss';
import ordersTableConfig from './orders.table-config.json';
import { useManagerOrdersTableResult } from './useManageOrdersTable';

import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { usePreferences } from 'hooks/usePreferences';
import { CurrentPage } from 'common/utils/config-models';
import { ManageOrdersTableHeader } from './ManageOrdersTableHeader';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { OrderItem } from '@amzn/ito-client';
import { UserPermissions } from 'services/user-permissions';
import { HardwareOrderStatus } from 'interfaces';

const uploadedOrdersVisibleColumns = [
  'orderId',
  'ageDays',
  'requestedBy'
];

const PREFERENCES_KEY = 'ManageOrdersPreferences';
const columns: ColumnSetting[] = ordersTableConfig.columns;
const FILTERING_PROPERTIES = buildFilteringProperties(columns);
const VISIBLE_CONTENT_COLUMN_OPTIONS = buildVisibleContentOptions(columns);
const LOCATION_OPTIONS = buildMultiSelectLocationOptions();

// REMOVE PENDING ONCE ALL ORDERS ARE OUT OF PENDING STATUS
const STATUS_OPTIONS = [
  { label: 'Assigned', value: HardwareOrderStatus.ASSIGNED },
  { label: 'Work In Progress', value: HardwareOrderStatus.WORK_IN_PROGRESS},
  { label: 'Pending', value: HardwareOrderStatus.PENDING },
  { label: 'Pending Quote', value: HardwareOrderStatus.PENDING_QUOTE },
  { label: 'Pending Requester Info', value: HardwareOrderStatus.PENDING_REQ_INFO },
  { label: 'Pending Approval', value: HardwareOrderStatus.PENDING_APPROVAL },
  { label: 'Pending Delivery', value: HardwareOrderStatus.PENDING_DELIVERY },
  { label: 'Cancelled', value: HardwareOrderStatus.CANCELLED},
  { label: 'Delivered', value: HardwareOrderStatus.DELIVERED},
];

const EXPEDITED_OPTIONS = [
  { label: 'Expedited', value: '' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
];

export interface ManageOrdersTableProps {
  updateOrderSelected?:  (showSplitPanel: boolean, orderSelected: string, orderItems: OrderItem[] | undefined) => void;
  useFlashBarItemsActions?: useFlashBarItemsActions;
  useManageOrdersTableHook?: useManagerOrdersTableResult;
  fileId?: string;
  fileName?: string;
}

export const ManageOrdersTable: FunctionComponent<ManageOrdersTableProps> = (props: ManageOrdersTableProps) => {
  // Actions
  const { useFlashBarItemsActions, useManageOrdersTableHook } = props;
  const { attributes, actions } = useManageOrdersTableHook!;
  const [loading, ] = useState(false);

  const [currentSorting, setCurrentSorting] = useState<SortingState | undefined>(undefined);

  const url = new URL(window.location.href);

  // Show and hides the split panel based on items selected
  /* istanbul ignore next */
  useEffect(() => {
    if(attributes.selectedItems.length === 0) {
      props.updateOrderSelected!(false, "", []);
    } else if(attributes.selectedItems.length >= 1 && attributes.selectedItems[attributes.selectedItems.length -1]) {
      const orderSelected = attributes.selectedItems[attributes.selectedItems.length - 1]?.orderReference ?? "";
      const orderItems = attributes.selectedItems[attributes.selectedItems.length -1]?.items;
      props.updateOrderSelected!(true, orderSelected, orderItems);
    }
  }, [attributes.selectedItems]);

  // Preferences
  const { currentPreferences, onPreferencesConfirmHandler } = usePreferences({
    preferencesKey: PREFERENCES_KEY,
    refreshData: () => refreshData(true),
    columnsSettings: columns,
    defaultPaging: defaultPagingOptions[1].value
  });
  
  const [currentPageIndex, setCurrentPageIndex] = useState<CurrentPage>({ index: 1, isPageReset: true });
  const [selectedStatus, setSelectedStatus] = useState<OptionDefinition[]>([]);
  const [selectedExpedited, setSelectedExpedited] = useState(EXPEDITED_OPTIONS[0]);

  const ASSIGNED_TO_OPTIONS: SelectProps.Option[] = [
    { label: 'unassigned', value: '-' },
    ...buildSelectOptionsFromStrings(attributes.procurementMembersSystem)
  ];
  
  const currentUsername = UserPermissions.instance.username;

  const [selectedAssignee, setSelectedAssignee] = useState<OptionDefinition[]>([
    { label: currentUsername, value: currentUsername }
  ]);
  const [selectedLocations, setSelectedLocations] = useState<OptionDefinition[]>([]);

  // To get orders from ITO
  const {ordersData, isLoading, doGetOrders} = useGetOrders(
    { pageSize: currentPreferences.pageSize, pageIndex: 1 },
    undefined
  );

  useEffect(() => {
    refreshData(true);
  }, [selectedAssignee, selectedStatus, selectedLocations, selectedExpedited, attributes.currentFilteringQuery, currentSorting?.sortBy, currentSorting?.sortOrder]);

  useEffect(() => {
    if (!currentPageIndex.isPageReset) {
      refreshData(false);
    }
  }, [currentPageIndex.index, currentPageIndex.isPageReset]);

  /* istanbul ignore next */
  const onStatusChange: NonCancelableEventHandler<MultiselectProps.MultiselectChangeDetail> = e => {
    setSelectedStatus([...e.detail.selectedOptions]);
  };  

  /* istanbul ignore next */
  const onExpeditedChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = e => {
    setSelectedExpedited({
      label: e.detail.selectedOption.label!,
      value: e.detail.selectedOption.value!
    });
  };

  /* istanbul ignore next */
  const onAssignedChange: NonCancelableEventHandler<MultiselectProps.MultiselectChangeDetail> = e => {
    setSelectedAssignee([...e.detail.selectedOptions]);
  };
  
  const onLocationChange: NonCancelableEventHandler<MultiselectProps.MultiselectChangeDetail> = e => {
    setSelectedLocations([...e.detail.selectedOptions]);
  };

  // Sorting
  /* istanbul ignore next */
  const onSortingChange: NonCancelableEventHandler<TableProps.SortingState<Order>> = async e => {
    const filterField = e.detail.sortingColumn.sortingField;
    const sortBy = filterField != "ageDays" ? filterField : "requested.on";
    const sortOrder = e.detail.isDescending ? 'sort.desc' : 'sort.asc';
    setCurrentSorting({ sortBy, sortOrder });
    collectionProps.onSortingChange!(e);
  };

  const { collectionProps } = useCollection(ordersData?.orders ?? [], {
    sorting: {}
  });

  // Pagination
  /* istanbul ignore next */
  const onPageClick: NonCancelableEventHandler<PaginationProps.ChangeDetail> = e => {
    setCurrentPageIndex({ index: e.detail.currentPageIndex, isPageReset: false });
  };

  /* istanbul ignore next */
  const refreshData = (clearPreviousData: boolean) => {
    let pageIndexForQuery = currentPageIndex.index;
    if (clearPreviousData) {
      pageIndexForQuery = 1;
      setCurrentPageIndex({ index: 1, isPageReset: true });
    }

    const filters: { [key: string]: string[] } = convertTokensToPropertyFilter(attributes.currentFilteringQuery.tokens);

    if (selectedStatus.length > 0) {
      filters.status = selectedStatus.map(item => item.value as string);
    }
    if (selectedAssignee.length > 0) {
      filters.assignedTo = selectedAssignee.map(item => item.value as string);
    }
    if (selectedLocations.length > 0) {
      filters['details.deliverTo.country'] = selectedLocations.map(item => item.value as string);
    }
    if (selectedExpedited.value) {
      filters['details.isExpedite'] = [selectedExpedited.value];
    }
    if (currentSorting && currentSorting.sortOrder && currentSorting.sortBy) {
      filters[currentSorting.sortOrder] = [currentSorting.sortBy];
    }
    if (attributes.currentFilteringQuery && attributes.currentFilteringQuery.tokens) {
      for (const token of attributes.currentFilteringQuery.tokens) {
        if (!filters[token.propertyKey!]) filters[token.propertyKey!] = [];

        filters[token.propertyKey!].push(token.value as string);
      }
    }

    actions.refreshAttributes();
    doGetOrders({
      filters,
      pageSize: currentPreferences.pageSize!,
      pageIndex: pageIndexForQuery
    });
  };

  // Ready to display
  let pagesCount = 0;
  let totalResults = 0;
  if (ordersData && ordersData.totalResults && ordersData.totalResults > 0) {
    totalResults = ordersData.totalResults;
    pagesCount = Math.ceil(ordersData.totalResults / currentPreferences.pageSize!);
  }

  const uploadErrorColumnIndex = columns.findIndex(c => c.id === 'uploadError');
  if (props.fileId && uploadErrorColumnIndex >= 0) {
    currentPreferences.visibleContent = uploadedOrdersVisibleColumns;
    columns[uploadErrorColumnIndex].visible = 'yes';
  } else {
    if (uploadErrorColumnIndex >= 0) {
      columns[uploadErrorColumnIndex].visible = 'never';
    }
  }

  const currentColumnDefinitions = buildColumnDefinitions(columns, currentPreferences.visibleContent);

  if (loading) {
    return (
      <Container>
        <Header variant="h2" data-testid="download-loading">
          {getString('manageOrders.table.downloadloading')}
        </Header>
        <Spinner size="large" />
      </Container>
    );
  }

  return (
    <Box data-testid="manage-orders-box">
      <Table
        {...collectionProps}
        data-testid="manage-orders-table"
        loading={isLoading}
        onSelectionChange={actions.onSelectionChangeHandler}
        selectedItems={attributes.selectedItems}
        header={
          <ManageOrdersTableHeader
            totalResults={totalResults}
            selectedItems={attributes.selectedItems!}
            refreshData={refreshData}
            useFlashBarItemsActions={useFlashBarItemsActions!}
            procurementMembersSelectOptions={buildSelectOptionsFromStrings(attributes.procurementMembers)}
          />
        }
        filter={
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }]}>
            <PropertyFilter
              expandToViewport
              query={attributes.currentFilteringQuery}
              onChange={actions.onPropertyFilterChange}
              filteringProperties={FILTERING_PROPERTIES}
              i18nStrings={getPropertyFilterString()}
              filteringPlaceholder={getString("manageOrders.table.textFilter")}
              hideOperations={true}
              data-testid="orders-text-filter"
            />
            <Select
              data-testid="select-expedited"
              selectedOption={selectedExpedited}
              onChange={onExpeditedChange}
              options={EXPEDITED_OPTIONS}
              selectedAriaLabel="Selected"
            />
            <Multiselect
              data-testid="select-status"
              placeholder="Status"
              filteringType="auto"
              selectedOptions={selectedStatus}
              onChange={onStatusChange}
              options={STATUS_OPTIONS}
              selectedAriaLabel="Selected"
            />
            <Multiselect
              data-testid="select-assigned"
              placeholder="Assigned To"
              filteringType="auto"
              selectedOptions={selectedAssignee}
              onChange={onAssignedChange}
              options={ASSIGNED_TO_OPTIONS}
              selectedAriaLabel="Selected"
            
            />
            <Multiselect
              expandToViewport
              data-testid="select-location"
              placeholder="Region / Country"
              filteringType="auto"
              selectedOptions={selectedLocations}
              onChange={onLocationChange}
              options={LOCATION_OPTIONS}
              selectedAriaLabel="Selected"
              tokenLimit={2}
            />
          </Grid>
        }
        pagination={
          <Pagination
            currentPageIndex={currentPageIndex.index}
            pagesCount={pagesCount}
            onChange={onPageClick}
            ariaLabels={{
              nextPageLabel: getString('common.pagination.nextPage'),
              previousPageLabel: getString('common.pagination.nextPage'),
              pageLabel: (pageNumber: number) => getString('common.pagination.currentPage', undefined, { pageNumber, pagesCount })
            }}
          />
        }
        onSortingChange={onSortingChange}
        empty={<EmptyTableBox />}
        stripedRows={currentPreferences.stripedRows}
        preferences={
          props.fileId ? undefined : (
            <CollectionPreferences
              title={getString('common.preferences.title')}
              confirmLabel={getString('common.preferences.confirmLabel')}
              cancelLabel={getString('common.preferences.cancelLabel')}
              pageSizePreference={{
                title: getString('common.preferences.pageSize.title'),
                options: defaultPagingOptions
              }}
              stripedRowsPreference={{
                label: getString('common.preferences.stripedRows.label'),
                description: getString('common.preferences.stripedRows.description')
              }}
              visibleContentPreference={{
                title: getString('common.preferences.visibleContentPreference.title'),
                options: [
                  {
                    label: getString('common.preferences.visibleContentPreference.columns'),
                    options: VISIBLE_CONTENT_COLUMN_OPTIONS
                  }
                ]
              }}
              preferences={currentPreferences}
              onConfirm={onPreferencesConfirmHandler}
            />
          )
        }
        columnDefinitions={currentColumnDefinitions}
        loadingText={getString('common.loading')}
        items={ordersData?.orders!}
        resizableColumns={true}
        selectionType="multi"
        variant="full-page"
        wrapLines
      />
    </Box>
  );
};
