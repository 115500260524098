/* istanbul ignore file */
import { FormField, Select } from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { FC, useEffect, useState } from "react";

interface SelectedOption {
    label: string;
    value: string;
}

interface FormFieldWithSelectProps {
    label: string;
    options: { label: string; value: string }[];
    onSelectionChange: (value: string) => void;
    selectedValue: string;
    error?: string;
    disabled?: boolean;
    ariaRequired?: boolean;
    dataCy?: string;
}

const FormFieldWithSelect: FC<FormFieldWithSelectProps> = ({
    label,
    options,
    onSelectionChange,
    error,
    disabled,
    selectedValue,
    ariaRequired,
    dataCy,
}) => {
    const [selectedOption, setSelectedOption] = useState<SelectedOption>({ label: "", value: "" });

    useEffect(() => {
        // Find the option that matches the selectedValue
        const matchingOption = options.find((option) => option.value === selectedValue);
        if (matchingOption) {
            setSelectedOption(matchingOption);
        }
    }, [selectedValue, options]);

    const onChangeField = (option: OptionDefinition) => {
        // Provide fallback values for label and value
        const selectedLabel = option.label ?? "";
        const selectedValue = option.value ?? "";

        setSelectedOption({ label: selectedLabel, value: selectedValue });

        // Call the callback function if value is not undefined
        if (selectedValue) {
            onSelectionChange(selectedValue);
        }
    };

    return (
        <FormField data-testid="formfield-test" stretch label={label} errorText={error}>
            <Select
                data-cy={dataCy}
                ariaRequired={ariaRequired}
                disabled={disabled}
                data-testid="formfield-select"
                selectedOption={selectedOption}
                options={options}
                onChange={(event) => onChangeField(event.detail.selectedOption)}
            />
        </FormField>
    );
};

export default FormFieldWithSelect;
