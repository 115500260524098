import {
    Box,
    Button,
    Container,
    Flashbar,
    Form,
    Header,
    Link,
    SpaceBetween,
    Spinner,
} from "@amzn/awsui-components-react";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import LeaveCreateCatalogModal from "components/CreateCatalogForm/LeaveCreateCatalogModal/LeaveCreateCatalogModal";

import AvailableRegionsForm from "../CreateCatalogForm/AvailableRegionsForm/AvailableRegionsForm";
import ProductInformationForm from "../CreateCatalogForm/ProductInformationForm/ProductInformationForm";
import TaxonomyForm from "../CreateCatalogForm/TaxonomyForm/TaxonomyForm";
import useEditCatalogForm from "./useEditCatalogForm";

const EditCatalogForm = () => {
    const {
        catalogItemValues,
        handleInputChange,
        requiredFieldsErrors,
        handleEditCatalogButton,
        submitStatus,
        handleCancelClick,
        isLeaveModalVisible,
        handleDismissModal,
        isLoading,
        flashBarItems,
    } = useEditCatalogForm();

    return (
        /* istanbul ignore next */
        <Form
            data-testid="edit-catalog-form"
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={handleCancelClick}>
                        {getString("editCatalog.cancelFormButton")}
                    </Button>

                    <Button
                        data-cy="edit-catalog-submit-button"
                        variant="primary"
                        onClick={handleEditCatalogButton}
                        loading={submitStatus.loading}
                    >
                        {getString("editCatalog.editCatalogButton")}
                    </Button>
                </SpaceBetween>
            }
            header={
                <SpaceBetween size="m">
                    <Flashbar items={flashBarItems} />
                    <Header variant="h1" info={<Link>{getString("editCatalog.formHeaderInfo")}</Link>}>
                        {getString("editCatalog.formHeader")}
                    </Header>
                    <Box color="text-status-inactive">{getString("editCatalog.formDescription")}</Box>
                </SpaceBetween>
            }
        >
            {isLoading && (
                <Container>
                    <Box textAlign="center">
                        <Spinner size="large" />
                    </Box>
                </Container>
            )}
            {!isLoading && (
                <SpaceBetween size="xl">
                    <ProductInformationForm
                        catalogItemValues={catalogItemValues}
                        handleInputChange={handleInputChange}
                        errors={requiredFieldsErrors}
                    />
                    <TaxonomyForm
                        isEditMode={true}
                        catalogItemValues={catalogItemValues}
                        handleInputChange={handleInputChange}
                        errors={requiredFieldsErrors}
                    />
                    <AvailableRegionsForm
                        handleInputChange={handleInputChange}
                        selectedRegions={catalogItemValues.region_restrictions}
                    />
                </SpaceBetween>
            )}
            <LeaveCreateCatalogModal visible={isLeaveModalVisible} onDismiss={handleDismissModal} />
        </Form>
    );
};
export default EditCatalogForm;
