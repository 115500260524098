import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';

import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from '../../common/navigation/SideNavigationMenu';
import OrderDetailsTabContainer from 'components/OrderDetailsContent/OrderDetailsTabContainer';

import Paths from "common/paths/paths";
import { useFlashBarItems } from 'common/UseFlashBarItems/useFlashBarItems';
import { Flashbar, SplitPanel } from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { OrderSubLineItemsTable } from 'components/OrderDetailsContent/tabs/ItemsRequestedTab/SubLineItemsTable/OrderSubLineItemsTable';
import { useOrderEdit } from './useOrderEdit';

interface OrderDetailsPageProps {
  isExternalView?: boolean
}

const OrderDetailsPage = (props: OrderDetailsPageProps) => {
  const flashBarItemsHook = useFlashBarItems();
  const { items, actions } = useOrderEdit(flashBarItemsHook.actions, props.isExternalView);

  const [navigationOpen, setNavigationOpen] = useState(false);

  const breadcrumbs = {
    items:
      [
        { text: Paths.Admin.name, href: Paths.Admin.url },
        { text: Paths.manageOrders.name, href: Paths.manageOrders.url },
        { text: items.orderReference, href: "#" }
      ]
  }

  const onSplitPanelToggle: NonCancelableEventHandler = () => {
    actions.showSublinePanel(false);
  };

  const getSelectedLineItemName = () => {
    if (items.lastLineItemSelected && items.lastLineItemSelected.itemInformation && (items.lastLineItemSelected.itemInformation as any).name) {
      return (items.lastLineItemSelected.itemInformation as any).name as string;
    }
    return "";
  }

  return (
    <AppLayout
      content={
        <OrderDetailsTabContainer
          useOrderEditItems={items}
          useOrderEditActions={actions}
          useFlashBarItemsActions={flashBarItemsHook.actions}
        />}
      contentType="default"
      breadcrumbs={!props.isExternalView && <NavigationBreadcrumbs items={breadcrumbs.items} />}
      navigation={<SideNavigationMenu />}
      navigationOpen={navigationOpen}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
      navigationHide={props.isExternalView}
      notifications={<Flashbar items={flashBarItemsHook.flashBarItems} stackItems data-testid="notifications-flashbar"/>}
      toolsHide={true}
      splitPanelOpen={items.showSplitPanel}
      onSplitPanelToggle={onSplitPanelToggle}
      splitPanel={
        <SplitPanel
          closeBehavior="hide"
          hidePreferencesButton={true}
          header={getSelectedLineItemName()}
        >
          <OrderSubLineItemsTable
            useOrderEditItems={items}
            useOrderEditActions={actions}
            reloadOrder={actions.reloadOrder}
            flashBarItemsActions={flashBarItemsHook.actions}
          />
        </SplitPanel>
      }

    />
  );
}

export default OrderDetailsPage;