import { StatusCodes } from "http-status-codes";
import { CatalogItemValues } from "interfaces/catalogSchema";

import { CATALOGICAL_ENDPOINT_V2 } from "../configuration/config";
import { CatalogItemCreationResponse, CatalogItemServiceResponse } from "../interfaces/catalogSchema";
import { BaseService } from "./base-service";
import { CSRFService } from "./csrf-service";

export class CatalogicalServiceV2 extends BaseService {
    // Instance of CatalogicalServiceV2
    public static instance = new CatalogicalServiceV2(CATALOGICAL_ENDPOINT_V2);

    /**
     * Fetches a limited number of catalog items with optional sorting, filtering, and fuzzy search.
     * @param {number} limit - The maximum number of items to retrieve. Default is 10.
     * @param {number} offset - The offset from which to start retrieving items. Default is 0.
     * @param {string} sortBy - The field by which to sort the items. Default is "name".
     * @param {string} sortDirection - The direction of sorting, either "asc" for ascending or "desc" for descending. Default is "asc".
     * @param {string} filters - A JSON string of filter conditions.
     * @param {boolean} fuzzy - Indicates whether a fuzzy search should be performed. Default is false.
     * @param {string} keywords - The keywords to use for a fuzzy search. Ignored if fuzzy is false. Default is an empty string ("").
     * @returns {Promise<CatalogItemServiceResponse>} A promise that resolves to the response with catalog items.
     */
    public async getAllCatalogItems(
        limit: number = 10,
        offset: number = 0,
        sortBy: string = "name",
        sortDirection: string = "asc",
        filters: string = "",
        fuzzy: boolean = false,
        keywords: string = "",
    ): Promise<CatalogItemServiceResponse> {
        const queryParams = new URLSearchParams({
            limit: `${limit}`,
            offset: `${offset}`,
            sortBy: `${sortBy}`,
            sortDirection: `${sortDirection}`,
        });
        if (filters && filters.length > 0) {
            queryParams.append("filter", filters);
        }
        if (fuzzy && keywords.length > 0) {
            queryParams.append("fuzzy", `${fuzzy}`);
            queryParams.append("keywords", keywords);
        }

        const url = `/items/hardware/?${queryParams.toString()}`;
        const response = await this.fetch(url);
        if ([StatusCodes.OK, StatusCodes.CREATED].includes(response.status)) {
            return response.json();
        }

        throw new Error("Failed to fetch catalog items");
    }

    public async createCatalogItem(CatalogItemValues: CatalogItemValues): Promise<CatalogItemCreationResponse> {
        const url = `/item/software/`;

        // Create an instance of CSRF Token Service
        const csrfService = new CSRFService();
        const csrfToken = await csrfService.getToken();

        //Request body with necessary format

        const requestBody = {
            item: {
                name: CatalogItemValues.name,
                sku: CatalogItemValues.sku,
                subtype: CatalogItemValues.subtype,
                short_name: CatalogItemValues.short_name,
                status: CatalogItemValues.status,
                manufacturer: CatalogItemValues.manufacturer,
                description: CatalogItemValues.description,
                short_description: CatalogItemValues.short_description,
                categories: CatalogItemValues.categories,
                price: {
                    amount: parseFloat(CatalogItemValues.amount),
                    currency: CatalogItemValues.currency,
                },
                os: CatalogItemValues.os,
                references: {
                    order_redirect_url: CatalogItemValues.order_redirect_url,
                    product_download_url: CatalogItemValues.product_download_url,
                },
                restock_threshold: parseFloat(CatalogItemValues.restock_threshold),
                retail_price: {
                    amount: parseFloat(CatalogItemValues.retail_price),
                    currency: CatalogItemValues.currency,
                },
                license_activation_instructions: CatalogItemValues.license_activation_instructions,
                availability_restrictions: [
                    {
                        level: "region",
                        values: CatalogItemValues.region_restrictions,
                    },
                ],
            },
        };

        const options = {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json",
                "x-csrf-token": csrfToken,
            },
        };

        const response = await this.fetch(url, options);
        if ([StatusCodes.OK, StatusCodes.CREATED].includes(response.status)) {
            return response.json();
        } else if (response.status === StatusCodes.BAD_REQUEST) {
            const errorResponse = await response.json();
            if (errorResponse.message.includes("already exists")) {
                throw new Error(errorResponse.message);
            } else {
                throw new Error("Error occurred during catalog item creation, please try again");
            }
        } else {
            throw new Error(`An unexpected error occurred during catalog item creation`);
        }
    }

    public async editCatalogItem(CatalogItemValues: CatalogItemValues): Promise<CatalogItemCreationResponse> {
        const url = `/item/software/${CatalogItemValues.subtype}/${CatalogItemValues.manufacturer}/${CatalogItemValues.short_name}/${CatalogItemValues.sku}`;
        // Create an instance of CSRF Token Service
        const csrfService = new CSRFService();
        const csrfToken = await csrfService.getToken();

        const requestBody = {
            item: {
                name: CatalogItemValues.name,
                sku: CatalogItemValues.sku,
                subtype: CatalogItemValues.subtype,
                short_name: CatalogItemValues.short_name,
                status: CatalogItemValues.status,
                manufacturer: CatalogItemValues.manufacturer,
                description: CatalogItemValues.description,
                short_description: CatalogItemValues.short_description,
                categories: CatalogItemValues.categories,
                price: {
                    amount: parseFloat(CatalogItemValues.amount),
                    currency: CatalogItemValues.currency,
                },
                os: CatalogItemValues.os,
                references: {
                    order_redirect_url: CatalogItemValues.order_redirect_url,
                    product_download_url: CatalogItemValues.product_download_url,
                },
                restock_threshold: parseFloat(CatalogItemValues.restock_threshold),
                retail_price: {
                    amount: parseFloat(CatalogItemValues.retail_price),
                    currency: CatalogItemValues.currency,
                },
                license_activation_instructions: CatalogItemValues.license_activation_instructions,
                availability_restrictions: [
                    {
                        level: "region",
                        values: CatalogItemValues.region_restrictions,
                    },
                ],
            },
        };

        const options = {
            method: "PUT",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json",
                "x-csrf-token": csrfToken,
            },
        };

        const response = await this.fetch(url, options);
        if ([StatusCodes.OK, StatusCodes.CREATED].includes(response.status)) {
            return response.json();
        } else if (response.status === StatusCodes.BAD_REQUEST) {
            const errorResponse = await response.json();
            throw new Error("Error occurred during catalog item update, please try again");
        } else {
            const errorResponse = await response.json();
            throw new Error(`An unexpected error occurred during catalog item update`);
        }
    }

    public async getCatalogItemByTaxonomyId(id: string): Promise<CatalogItemCreationResponse> {
        if (!id) {
            throw new Error("Taxonomy ID is undefined");
        }
        const formattedTaxonomyID = id.replace(/^atn::software\//, "");

        const url = `/item/software/${formattedTaxonomyID}`;
        const response = await this.fetch(url);

        /* istanbul ignore next */
        if (response.status === StatusCodes.OK) {
            return response.json();
        }

        throw new Error("Failed to fetch catalog item with Taxonomy ID: " + id);
    }

    public async deactivateCatalogItem(id: string): Promise<boolean> {
        const formattedTaxonomyID = id.replace(/^atn::software\//, "");
        const url = `/item/software/${formattedTaxonomyID}`;
        const csrfService = new CSRFService();
        const csrfToken = await csrfService.getToken();

        //Request body with necessary format
        const requestBody = {
            isActive: false,
        };

        const options = {
            method: "PATCH",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json",
                "x-csrf-token": csrfToken,
            },
        };
        const response = await this.fetch(url, options);

        if ([StatusCodes.OK, StatusCodes.CREATED].includes(response.status)) {
            return true;
        } else if (response.status === StatusCodes.BAD_REQUEST) {
            throw new Error("Error occurred during catalog item deactivation, please try again");
        } else {
            throw new Error(`An unexpected error occurred during catalog item deactivation`);
        }
    }
}
