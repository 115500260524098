import {
    Box,
    ColumnLayout,
    Container,
    FileUpload,
    FormField,
    Grid,
    Header,
    Input,
    SpaceBetween,
    Textarea,
} from "@amzn/awsui-components-react";
import FormFieldWithSelect from "common/FormFieldWithSelect/FormFieldWithSelect";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { CatalogItemValues } from "interfaces/catalogSchema";
import { useState } from "react";

interface ProductInformationFormProps {
    catalogItemValues: CatalogItemValues;
    handleInputChange: (field: keyof CatalogItemValues, value: string | string[]) => void;
    errors: {
        nameError: string;
        amountError: string;
    };
}

const currencyOptions = [
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
    { label: "JPY", value: "JPY" },
    { label: "CRI", value: "CRI" },
];
const statusOptions = [
    { label: "Sanctioned", value: "sanctioned" },
    { label: "Unsanctioned", value: "unsanctioned" },
    { label: "Unavailable", value: "unavailable" },
];

const ProductInformationForm = ({ catalogItemValues, handleInputChange, errors }: ProductInformationFormProps) => {
    const [valueFileUpload] = useState([]);

    /* istanbul ignore next */
    return (
        <Container
            data-testid="product-information-form"
            header={<Header variant="h2">{getString("createCatalog.formProductInformation.formHeader")}</Header>}
        >
            <SpaceBetween size="m">
            </SpaceBetween>
        </Container>
    );
};
export default ProductInformationForm;
