import { BrowserRouter, Route, Routes } from "react-router-dom";

import ExportsPage from "views/ExportsPage";
import OrderDetailsPage from "views/OrdersDetailsPage";
import CustomHeader from "../common/CustomHeader/CustomHeader";
import Paths from "../common/paths/paths";
import CreateCatalogPage from "../views/CreateCatalogPage";
import EditCatalogPage from "../views/EditCatalogPage";
import ManageCatalogPage from "../views/ManageCatalogPage";
import ManageOrdersPage from "../views/ManageOrdersPage";
import AttachmentDownloadPage from "views/AttachmentDownloadPage";
import ProtectedRoutes from "./ProtectedRoutes";

import { UserPermissions } from "services/user-permissions";
import { PERMISSIONS } from "configuration/config";
import NotFoundPage from "views/NotFoundPage";

const AdminRoutes = () => {
    const isAdminUser = UserPermissions.instance.hasPermission(PERMISSIONS.HARDWARE_PROCUREMENT);
    return (
    <BrowserRouter>
        <CustomHeader />
        <Routes>
            {/* Procurement only pages */}
            <Route element={<ProtectedRoutes isAllowed={isAdminUser} redirectPath={Paths.notFound.url} />}>
                <Route path={Paths.manageOrders.url} element={<ManageOrdersPage />} />
                <Route path={Paths.exports.url} element={<ExportsPage />} />
                <Route path={Paths.manageCatalog.url} element={<ManageCatalogPage />} />
                <Route path={Paths.createCatalog.url} element={<CreateCatalogPage />} />
                <Route path={Paths.editCatalog.url} element={<EditCatalogPage />} />
                <Route path="/*" element={<ManageOrdersPage />} />
            </Route>
            {/* Public pages */}
            <Route path={Paths.attachmentDownload.url} element={<AttachmentDownloadPage />}  />
            <Route path={Paths.orderDetails.url} element={<OrderDetailsPage isExternalView={!isAdminUser} />} />
            <Route path={Paths.notFound.url} element={<NotFoundPage />}/>
        </Routes>
    </BrowserRouter>
)};

export default AdminRoutes;
