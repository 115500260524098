import { useEffect } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';

import { useGetItemAttachment } from 'hooks';
import { Spinner } from '@amzn/awsui-components-react';
import { useParams } from 'react-router-dom';
import { useFlashBarItems } from 'common/UseFlashBarItems/useFlashBarItems';

const AttachmentDownloadPage = () => {

    // Parameter id
    const { id, fileName } = useParams<{ id: string, fileName: string }>();

    // Hooks
    const flashBarItemsHook = useFlashBarItems();
    const { data, error, isLoading, doGetItemAttachment } = useGetItemAttachment();

    // Checks if there is data for the file to download
    useEffect(() => {
        if (data && data.preSignedUrl) {
            window.location.href = data.preSignedUrl;
        }

        if (!data) {
            // Trigers the download of the attachment
            if (id) {
                doGetItemAttachment({
                    attachmentId: id,
                });
            }
        }

        if (error) {
            flashBarItemsHook.actions.addErrorFlashBarItem(error.message);
        }
    }, [data]);

    const text = error ? <p>{error.message}</p> : <p>Redirecting...</p>
    // Stub layout with a spinner
    return (
        <AppLayout
            content={(!data && !error) ? <Spinner size='large' /> : text}
            contentType="default"
            toolsHide={true}
            navigationHide={true}
        />
    );
}

export default AttachmentDownloadPage;