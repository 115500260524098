import get from "lodash/get";

import uiStrings from "./ui-strings.json";
import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris/property-filter";

/**
 * Gets the string value from the ui-string JSON based on specific path.
 * @param id The full path of the value to extract: "field.subfield.value1".  If stringPath is available, it should be only the key of the value: "value1".
 * @param stringPath Main path of the value to be extracted without the key, for "field.subfield.value1" tha main path is "field.subfield" and id is "value1".  If not present, id will be used as full path.
 */
export function getString(id: string, stringPath?: string, obj?: { [key: string]: any }): string {
    let fullpath = stringPath ? stringPath + "." + id : id;
    let outputString = get(uiStrings, fullpath, "");
    if (obj != null) {
        for (const key in obj) {
            const formatKey = "{{" + key + "}}";
            outputString = outputString.replace(formatKey, obj[key]);
        }
    }
    return outputString;
};

export const getPropertyFilterString = (): PropertyFilterProps.I18nStrings => {
    return {
        ...uiStrings.propertyFiltering,
        removeTokenButtonAriaLabel: (token) => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: (text) => `Use: "${text}"`,
    };
};
