import { useEffect, useState } from "react";
import { API_BASE_URL } from "../configuration/config";
import {
    GetOrderCommandInput,
    GetOrderCommandOutput,
    GetOrders_AllCommandInput,
    GetOrders_AllCommandOutput,
    ITO,
    Order,
    UpdateOrderStatusBatchCommandOutput,
    UpdateOrderStatusCommandOutput,
    GetCommentsCommandInput,
    GetCommentsCommandOutput,
    PostCommentCommandOutput,
    PostCommentCommandInput,
    UpdateOneOrderStatus,
    UpdateOrderStatusBatchCommandInput,
    UpdateOrderStatusCommandInput,
    UpdateItemDetailsCommandOutput,
    UpdateItemDetailsCommandInput,
    UpdateSubLineItemCommandInput,
    UpdateSubLineItemCommandOutput,
    AddSubLineItemCommandInput,
    AddSubLineItemCommandOutput,
    GetNotesCommandOutput,
    PostNoteCommandOutput,
    PostNoteCommandInput,
    GetNotesCommandInput
} from "@amzn/ito-client";
import {
    MetadataBearer
} from "@aws-sdk/types";
import { CALLER_ABORTED } from "@amzn/sentry-fetch-http-handler";
import { isProcurement } from "common/utils";
import { HardwareOrderStatus } from "interfaces";
import { getITOClient } from "./hooks-helpers";

export interface GetOrdersProps {
    clearPreviousData?: boolean;
    pageSize?: number;
    pageIndex?: number;
    filters?: Record<string, string[]>;
}

export interface GetFilesProps {
    clearPreviousData?: boolean;
    pageSize?: number;
    pageIndex?: number;
    fromAutoRefresh?: boolean;
}

export interface ApiError extends Error, MetadataBearer {

}

// Client
const { client, requestHandler } = getITOClient();

interface GetCommentsProps {
    taxonomyId: string;
    clearPreviousData?: boolean;
    pageSize?: number;
    pageIndex?: number;
    fromAutoRefresh?: boolean;
    filters?: Record<string, string[]>;
}

type GetOrderResult = GetOrders_AllCommandOutput | null | undefined;
export function useGetOrders(
    mainProps: GetOrdersProps,
    defaultValue: GetOrderResult,
): { ordersData: GetOrderResult; isLoading: boolean; doGetOrders: (props: GetOrdersProps) => void } {
    const [ordersData, setData] = useState<GetOrderResult>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);

    function doGetOrders(props: GetOrdersProps) {
        // its loading now
        setLoading(true);

        // Pagination
        if (!props.pageIndex) {
            props.pageIndex = 1;
        }
        if (!props.pageSize) {
            props.pageSize = 30;
        }

        // Filtering
        if (!props.filters) {
            props.filters = {};
        }

        // Clear prev data
        if (props.clearPreviousData && props.clearPreviousData === true) {
            setData(undefined);
        }
        const request: GetOrders_AllCommandInput = {
            pageSize: props.pageSize,
            nextToken: (props.pageIndex - 1).toString(),
            filters: props.filters,
        };

        client.getOrders_All(
            request,
            {},
            (err: Error, response?: GetOrders_AllCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response || !response.orders) {
                    console.error(err);
                    throw new Error("No orders data found");
                }

                setData(response);
                setLoading(false);
            },
        );
    }

    if (defaultValue === null) {
        // No default value, then get from API
        useEffect(() => {
            doGetOrders(mainProps);
        }, [defaultValue]);
    }

    return { ordersData, isLoading, doGetOrders };
}

export function useGetOrder(
    id: string | undefined,
    defaultValue?: GetOrderCommandOutput,
): { data: Order | undefined; isLoading: boolean; error: Error | undefined; doGetOrder: (id: string | undefined) => void } {
    const [data, setData] = useState<GetOrderCommandOutput | undefined>(defaultValue);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(true);

    function doGetOrder(id: string | undefined) {
        const request: GetOrderCommandInput = { orderId: id };
        client.getOrder(request, (err: any, response?: GetOrderCommandOutput | undefined) => {
            if (!response || err) {
                setError({ name: "Unable to get the order details", message: `Error: ${err}` });
            }
            else {
                setError(undefined);
                setData(response);
            }
            setLoading(false);
        });
    }

    // Order
    if (!defaultValue) {
        useEffect(() => {
            doGetOrder(id);
        }, []);
    }

    return { data, error, isLoading, doGetOrder };
}

export type GetCommentsResult = GetCommentsCommandOutput | null | undefined;
export function useGetComments(defaultValue: GetCommentsResult) {
    const [comments, setComments] = useState<GetCommentsResult>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [errorForGetComments, setError] = useState<ApiError | null>(null);

    function doGetComments(props: GetCommentsProps) {
        if (!props.fromAutoRefresh) {
            setLoading(true);
        }

        // Filtering
        if (!props.filters) props.filters = {};

        const request: GetCommentsCommandInput = {
            taxonomyId: props.taxonomyId,
            filters: props.filters,
            pageSize: 100,
        };

        client.getComments(request, (err: ApiError, response?: GetCommentsCommandOutput | undefined) => {
            if (err && err.message === CALLER_ABORTED) {
                return;
            }
            if (err) {
                setError(err);
                setComments({
                    ...err,
                    totalResults: 0,
                    pageSize: 0,
                    comments: []
                });
                setLoading(false);
                return;
            }

            if (!response || !response.comments) {
                console.error(err);
                throw new Error("No files data found");
            }
            response.comments.reverse();
            setComments(response);
            setLoading(false);
        });
    }
    return { comments, isLoading, doGetComments, errorForGetComments, setComments };
}

export function usePostComment() {
    const [error, setError] = useState<Error | null>(null);
    const [response, setResponse] = useState<PostCommentCommandOutput | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    async function doPostComment(taxonomyId: string, comment: string, csrfToken: string, tags: string[]) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        if (await isProcurement()) {
            tags.push("procurement");
        }

        const request: PostCommentCommandInput = {
            taxonomyId,
            comment,
            tags,
        };

        client.postComment(request, (err: Error, response?: PostCommentCommandOutput | undefined) => {
            if (err && err.message === CALLER_ABORTED) {
                return;
            }

            if (!response) {
                console.error(err);
                setError(error);
                throw new Error("No comment was posted");
            }
            setResponse(response);
            setLoading(false);
        });
    }

    return { response, isLoading, error, doPostComment };
}

export type GetNotesResult = GetCommentsCommandOutput | null | undefined;
export function useGetNotes(defaultValue: GetNotesResult) {
    const [notes, setNotes] = useState<GetNotesResult>(defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [errorForGetNotes, setError] = useState<ApiError | null>(null);

    function doGetNotes(props: GetCommentsProps) {
        if (!props.fromAutoRefresh) {
            setLoading(true);
        }

        // Filtering
        if (!props.filters) props.filters = {};

        const request: GetNotesCommandInput = {
            taxonomyId: props.taxonomyId,
            filters: props.filters,
            pageSize: 100,
        };

        client.getNotes(request, (err: ApiError, response?: GetNotesCommandOutput | undefined) => {
            if (err && err.message === CALLER_ABORTED) {
                return;
            }
            if (err) {
                setError(err);
                setNotes({
                    ...err,
                    totalResults: 0,
                    pageSize: 0,
                    comments: []
                });
                setLoading(false);
                return;
            }
            if (!response || !response.comments) {
                console.error(err);
                throw new Error("No files data found");
            }
            response.comments.reverse();
            setNotes(response);
            setLoading(false);
        });
        

        setLoading(false);
    }
    return { notes, isLoading, doGetNotes, setNotes, errorForGetNotes };
}

export function usePostNote() {
    const [error, setError] = useState<Error | null>(null);
    const [response, setResponse] = useState<PostNoteCommandOutput | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    async function doPostNote(taxonomyId: string, comment: string, csrfToken: string, tags: string[]) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        if (await isProcurement()) {
            tags.push("procurement");
        }

        const request: PostNoteCommandInput = {
            taxonomyId,
            comment,
            tags,
        };

        client.postNote(request, (err: Error, response?: PostNoteCommandOutput | undefined) => {
            if (err && err.message === CALLER_ABORTED) {
                return;
            }

            if (!response) {
                console.error(err);
                setError(error);
                throw new Error("No note was posted");
            }
            setResponse(response);
            setLoading(false);
        });
        setLoading(false);
    }

    return { response, isLoading, error, doPostNote };
}

// Omit the order id since it will receive an array of orders
interface UpdateOneOrderProps extends Omit<UpdateOneOrderStatus, "orderId"> { }

export function useUpdateOrdersStatus() {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<UpdateOrderStatusBatchCommandOutput | UpdateOrderStatusCommandOutput | undefined>(
        undefined,
    );

    /**
     * Receives a list of orders ids and one UpdateOneOrder request that will be applied to all orders.
     */
    function doUpdateOrdersById(orderIds: string[], updateProps: UpdateOneOrderProps, csrfToken: string) {
        if (orderIds.length === 0) {
            setError(new Error("Orders list is empty."));
            return;
        }

        const updateRequests: UpdateOneOrderStatus[] = orderIds.map((orderId) => {
            return { ...updateProps, orderId: orderId };
        });

        doUpdateOrderStatus(updateRequests, csrfToken);
    }

    /**
     * Receives a list of orders and one UpdateOrder request that will be applied to all orders.
     * But first performs the first assignment logic to update the order status.
     */
    function doUpdateOrders(orders: Order[], updateProps: UpdateOneOrderProps, csrfToken: string) {
        if (orders.length === 0) {
            setError(new Error("Orders list is empty."));
            return;
        }

        const updateRequests: (UpdateOneOrderStatus | undefined)[] = orders.map((order) => {
            const details = order.details as any;
            
            // If the order is blocked it can not be updated
            if (details && details.firstEdit && details.firstEdit !== "ham") {
                return undefined;
            }
            const request = { ...updateProps, orderId: order.orderId };
            
            return request;
        });
        // Get only the requests that are not undefined
        const definedRequests = updateRequests.filter(request => request !== undefined) as UpdateOneOrderStatus[];
        
        doUpdateOrderStatus(definedRequests, csrfToken);
    }

    function doUpdateOrderStatus(updateRequests: UpdateOneOrderStatus[], csrfToken: string) {
        if (updateRequests.length > 1) {
            doUpdateOrderStatus_Batch(updateRequests, csrfToken);
        } else {
            doUpdateOrderStatus_One(updateRequests[0], csrfToken);
        }
    }

    function doUpdateOrderStatus_Batch(orders: UpdateOneOrderStatus[], csrfToken: string) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        const request: UpdateOrderStatusBatchCommandInput = {
            orders,
        };

        client.updateOrderStatusBatch(
            request,
            {},
            (err: Error, response?: UpdateOrderStatusBatchCommandOutput | undefined) => {
                /* istanbul ignore next */
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                /* istanbul ignore next */
                if (!response || !response.orders) {
                    setLoading(false);
                    console.error(err);
                    setError(err);
                    throw err;
                }
                setData(response);
                setError(null);
                setLoading(false);
            },
        );
    }

    function doUpdateOrderStatus_One(orderStatusUpdate: UpdateOneOrderStatus, csrfToken: string) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);

        const request: UpdateOrderStatusCommandInput = { ...orderStatusUpdate };

        client.updateOrderStatus(
            request,
            {},
            (err: Error, response?: UpdateOrderStatusCommandOutput | undefined) => {
                if (err && err.message === CALLER_ABORTED) {
                    return;
                }

                if (!response) {
                    setLoading(false);
                    setError(err);
                    console.error(err);
                    throw err;
                }

                setData(response);
                setError(null);
                setLoading(false);
            },
        );
    }

    return { isLoading, setLoading, data, setData, error, setError, doUpdateOrders, doUpdateOrdersById };
}

export function useUpdateItemDetails() {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<UpdateItemDetailsCommandOutput | undefined>(undefined);

    async function doUpdateItemDetailsAsync(props: UpdateItemDetailsCommandInput, csrfToken: string) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);
        const request: UpdateItemDetailsCommandInput = { ...props };

        try {
            const response = await client.updateItemDetails(request);
            if (!response) {
                const err = { name: "No response", message: "No response" };
                console.error(err);
                setError(err);
            }
            setError(null);
            setData(response);
        }
        catch (err) {
            console.error(err);
            setError(err as Error);
        };
        setLoading(false);
    }

    return { isLoading, setLoading, data, setData, error, setError, doUpdateItemDetailsAsync };
}

export function useUpdateSubLineItemDetails() {
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<UpdateSubLineItemCommandOutput | undefined>(undefined);

    async function doUpdateSubLineItemAsync(props: UpdateSubLineItemCommandInput, csrfToken: string) {
        setLoading(true);

        requestHandler.pushHeader("x-csrf-token", csrfToken);
        const request: UpdateSubLineItemCommandInput = { ...props };

        try {
            const response = await client.updateSubLineItem(request);
            if (!response) {
                const err = { name: "No response", message: "No response" };
                console.error(err);
                setError(err);
            }
            setError(null);
            setData(response);
        }
        catch (err) {
            console.error(err);
            setError(err as Error);
        };

        setLoading(false);
    }

    return { isLoading, setLoading, data, setData, error, setError, doUpdateSubLineItemAsync };
}

export function useAddOrderSublineItem() {
    const [error, setError] = useState<Error | null>(null);
    const [response, setResponse] = useState<AddSubLineItemCommandOutput | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    async function doAddSublineItem(props: AddSubLineItemCommandInput, csrfToken: string) {
        requestHandler.pushHeader("x-csrf-token", csrfToken);
        const request: AddSubLineItemCommandInput = { ...props };

        try {
            const response = await client.addSubLineItem(request);
            if (!response) {
                const err = { name: "No response", message: "No response" };
                console.error(err);
                setError(err);
            }
            setError(null);
            setResponse(response);
        }
        catch (err) {
            console.error(err);
            setError(err as Error);
        };

        setLoading(false);
    }

    return { isLoading, response, error, doAddSublineItem };
}
