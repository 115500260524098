import { useEffect, useState } from "react";
import { SpaceBetween, Button, Textarea } from "@amzn/awsui-components-react";
import { CSRFService } from "services/csrf-service";
import { GetCommentsCommandOutput, GetNotesCommandOutput, Order } from "@amzn/ito-client";

import { GetNotesResult, useGetNotes, usePostNote } from "hooks";

import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { debounce } from "lodash";
import { MessageList } from "./Messages/MessagesList";
import { useOrderEditActions, useOrderEditItems } from "views/OrdersDetailsPage/useOrderEdit";

const stringPath = "orderDetails.tabs.messages";
const FIVE_MINUTES_IN_MS = 300000;
interface InternalCommentsTabProps {
    order: Order;
    useOrderEditItems: useOrderEditItems;
    useOrderEditActions: useOrderEditActions;
    flashBarItemsActions: useFlashBarItemsActions;
    notes?: GetNotesResult;
    setDefaultNotes?: React.Dispatch<React.SetStateAction<GetNotesResult>>;
}

const HALF_SECOND_IN_MS = 500;

export const InternalCommentsTab = (props: InternalCommentsTabProps) => {
    const [inputNote, setInputNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { notes, isLoading: isLoadingNotes, doGetNotes, errorForGetNotes } = useGetNotes(props.notes);
    const { doPostNote, error: errorPostNote, isLoading: isLoadingPost, response } = usePostNote();

    useEffect(() => {
        if (response && !errorPostNote) {
            // If adding internal note was successful, flag the order
            props.useOrderEditActions.flagOrderWithFirstEdit();
        } 
        doGetNotes({ taxonomyId: props.order.orderId!, filters: {} });
    }, [response, errorPostNote]);

    // Auto refresh
    useEffect(() => {
        const interval = setInterval(() => {
            doGetNotes({ taxonomyId: props.order.orderId!, filters: {} });
        }, FIVE_MINUTES_IN_MS); // 5 minutes
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setIsLoading(isLoadingNotes || isLoadingPost);
    }, [isLoadingNotes, isLoadingPost]);

    useEffect(() => {
        if(response && props.setDefaultNotes) {
            props.setDefaultNotes(notes)
        }
    }, [notes])
    
    const debouncedSendNote = debounce(sendNote, HALF_SECOND_IN_MS);
    function handleSendNote() {
        setIsLoading(true);
        setInputNote("");
        debouncedSendNote();
    }

    async function sendNote() {
        const csrfService = new CSRFService();
        const csrfToken = await csrfService.getToken();
        doPostNote(props.order.orderId!, inputNote, csrfToken, []);
    }

    return (
        <SpaceBetween size="xs">
            <SpaceBetween size="xl">{<MessageList comments={notes} />}</SpaceBetween>
            {props.useOrderEditItems.canBeEdited &&
                <>
                    <Textarea
                        onChange={(event) => setInputNote(event.detail.value)}
                        value={inputNote}
                        disabled={isLoading}
                        data-testid="internal-comment-input"
                    />
                    <Button
                        variant="primary"
                        onClick={handleSendNote}
                        loading={isLoading}
                        disabled={isLoading || !inputNote}
                        data-testid="internal-send-comment"
                    >
                        {getString(`${stringPath}.sendButton`)}
                    </Button>
                </>}
        </SpaceBetween>
    );
};
