import { Alert, AppLayout, ContentLayout, Header, TextContent } from "@amzn/awsui-components-react";
import { getString } from "common";

const NotFoundPage = () => {
    return (
        <AppLayout
            navigationHide={true}
            toolsHide={true}
            contentType="default"
            content={
                <ContentLayout
                    header={<Header variant="h1">{getString("notFound.title")}</Header>}
                >
                    <Alert
                        statusIconAriaLabel="Error"
                        type="error"
                        header={getString("notFound.description")}
                        data-testid="not-found-alert"
                    >
                        <TextContent>
                            <strong>{getString("notFound.tipKeyword")}</strong>{getString("notFound.tip")}
                        </TextContent>
                    </Alert>
                </ContentLayout>
            }
        />
    );
};

export default NotFoundPage;
