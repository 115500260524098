import { Container, StatusIndicator } from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { FunctionComponent } from "react";

interface DeactivateItemModalProps {
    visible: boolean;
    onDismiss: () => void;
    deactivateItem: () => void;
    loading: boolean;
    disabled: boolean;
    dataCy?: string;
}

const DeactivateItemModal: FunctionComponent<DeactivateItemModalProps> = ({
    visible,
    loading,
    onDismiss,
    deactivateItem,
    disabled,
    dataCy,
}) => {
    return (
        <Modal
            visible={visible}
            onDismiss={onDismiss}
            header={getString("manageCatalog.deactivateModal.modalHeader")}
            footer={
                <Box float="right">
                    <Button disabled={disabled} variant="link" onClick={onDismiss}>
                        {getString("manageCatalog.deactivateModal.cancelButton")}
                    </Button>
                    <Button data-cy={dataCy} loading={loading} variant="primary" onClick={() => deactivateItem()}>
                        {getString("manageCatalog.deactivateModal.deactivateButton")}
                    </Button>
                </Box>
            }
        >
            <Container>
                <StatusIndicator type="warning" />
                {getString("manageCatalog.deactivateModal.warningMessage")}
            </Container>
        </Modal>
    );
};

export default DeactivateItemModal;
