import { Container, StatusIndicator } from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import InternalButton from "common/navigation/InternalButton";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { FunctionComponent } from "react";

interface LeaveCreateCatalogModalProps {
    visible: boolean;
    onDismiss: () => void;
}

const LeaveCreateCatalogModal: FunctionComponent<LeaveCreateCatalogModalProps> = ({ visible, onDismiss }) => {
    return (
        <Modal
            visible={visible}
            onDismiss={onDismiss}
            header={getString("createCatalog.leavePageModal.modalHeader")}
            footer={
                <Box float="right">
                    <Button variant="link" onClick={onDismiss}>
                        {getString("createCatalog.leavePageModal.cancelButton")}
                    </Button>
                    <InternalButton variant="primary" href="/catalog">
                        {getString("createCatalog.leavePageModal.leaveButton")}
                    </InternalButton>
                </Box>
            }
        >
            <Container>
                <StatusIndicator type="warning" />
                {getString("createCatalog.leavePageModal.warningMessage")}
            </Container>
        </Modal>
    );
};

export default LeaveCreateCatalogModal;
