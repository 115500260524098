import React, { FunctionComponent } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  isAllowed: boolean,
  redirectPath: string,
  children?: React.ReactNode
}

const ProtectedRoutes: FunctionComponent<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  if (!props.isAllowed) {
    return <Navigate to={props.redirectPath} replace />
  }

  // Used when component is wrapped around an individual route
  if (props.children) {
    return props.children;
  }

  // Used when component is used as Layout component for multiple routes
  return <Outlet />;
};

export default ProtectedRoutes;