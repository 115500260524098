import { SpaceBetween } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';

interface EditableKeyValueProps {
  // From field
  label: string,
  viewDisplay: JSX.Element,
  editable: boolean
  editDisplay?: JSX.Element,

  // From page
  editMode?: boolean,
}

export const CustomViewAndEditFormField = (props: EditableKeyValueProps) => {

  // Only display mode
  if (props.editMode === undefined || !props.editable) {
    return (
      <div>
        <Box variant="awsui-key-label">{props.label}</Box>
        <SpaceBetween size="xs" direction="horizontal">
          {props.viewDisplay}
        </SpaceBetween>
      </div>
    )
  }

  // Editable mode
  return (
    <div>
      <Box variant="awsui-key-label">{props.label}</Box>
      {!props.editMode &&
        <SpaceBetween size="xs" direction="horizontal">
          {props.viewDisplay}
        </SpaceBetween>}
      {props.editMode && props.editable &&
        <SpaceBetween size="xs" direction="horizontal">
          {props.editDisplay}
        </SpaceBetween>}
    </div>
  )
};