import {
    Box,
    Button,
    ColumnLayout,
    DatePicker,
    FormField,
    Header,
    Input,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { Order, OrderItem, OrderSubLineItem } from '@amzn/ito-client';
import { getString } from 'common';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { useAddOrderSublineItem } from 'hooks';
import useInput from 'hooks/useInput';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CSRFService } from 'services/csrf-service';
import { useOrderEditActions } from 'views/OrdersDetailsPage/useOrderEdit';
import { ALPHANUMERIC_REGEXP, ALPHANUMERIC_WITH_DASH_REGEXP } from "common"
import ValidatedInput from 'common/InputWithValidator/ValidatedInput';

const stringPath = "orderDetails.tabs.itemsRequested.addSubLineItemModal";

export interface AddSubLineItemModalProps {
    order: Order,
    item: OrderItem,
    showModal: boolean,
    flashBarItemsActions: useFlashBarItemsActions,
    useOrderEditActions: useOrderEditActions,
    onDismiss: () => void,
}

export const AddSubLineItemModal = (props: AddSubLineItemModalProps) => {
    const itemName = (props.item.itemInformation as any)["name"];
    const { formValues, handleInputChange } = useInput<OrderSubLineItem>({
        quantityDelivered: 0,
        vendor: "",
        prNumber: "",
        poNumber: "",
        carrier: "",
        trackingNumber: "",
        deliveryDate: undefined
    });

    const [isLoading, setLoading] = useState(false);
    const { response, error, doAddSublineItem } = useAddOrderSublineItem();
    const [prNumberError, setPrNumberError] = useState("");
    const [vendorError, setVendorError] = useState("");

    const validateForm = () => {
        let isValid = true;
        if (!formValues.prNumber) {
            setPrNumberError("This field is required.");
            isValid = false;
        } else {
            setPrNumberError("");
        }

        if (!formValues.vendor) {
            setVendorError("This field is required.");
            isValid = false;
        } else {
            setVendorError("");
        }

        return isValid;
    };

    const onAddClick = async () => {
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        const csrfService = new CSRFService();
        const csrfToken = await csrfService.getToken();

        await doAddSublineItem({
            orderId: props.order.orderId,
            itemId: props.item.itemId,
            subLineItem: { ...formValues } as OrderSubLineItem
        }, csrfToken);
    }

    useEffect(() => {
        if (response && !error) {
            props.flashBarItemsActions.addSuccessFlashBarItem(`The sub-line item was added correctly.`);
            
            // If adding sub-line item was successful, flag the order
            props.useOrderEditActions.flagOrderWithFirstEdit();
            
            props.useOrderEditActions.reloadOrder(props.order.orderId);
            props.onDismiss();
        }
        else if (error) {
            props.flashBarItemsActions.addErrorFlashBarItem(`Error adding the sub-line item. ${error.message}`);
            props.onDismiss();
        }
        setLoading(false);
    }, [response, error]);

    const footerElement = (
        <SpaceBetween direction="horizontal" size="xs">
            <Button data-testid="cancel-button" disabled={isLoading} variant="link" onClick={props.onDismiss}>
                {getString(`${stringPath}.cancelButton`)}
            </Button>
            <Button data-testid="add-button" loading={isLoading} variant="primary" onClick={onAddClick}>
                {getString(`${stringPath}.addButton`)}
            </Button>
        </SpaceBetween>
    );

    return (
        <Modal
            data-testid="add-sublineitem-modal"
            onDismiss={props.onDismiss}
            visible={props.showModal}
            size="large"
            header={<Header variant="h2">{`${getString(`${stringPath}.header`)} ${itemName}`}</Header>}
            footer={<Box float="right">{footerElement}</Box>}
        >
            <ColumnLayout columns={4}>
                <FormField
                    errorText={prNumberError}
                    data-testid="field-prNumber"
                    label={
                        <span>
                            {getString(`${stringPath}.prNumber`)}
                            <i>{getString(`${stringPath}.requiredField`)}</i>
                        </span>}>
                    <ValidatedInput
                        regex={ALPHANUMERIC_REGEXP}
                        value={formValues.prNumber!}
                        onChange={(value) => handleInputChange("prNumber", value)}
                    />
                </FormField>
                <FormField data-testid="field-poNumber" label={getString(`${stringPath}.poNumber`)}>
                    <ValidatedInput
                        regex={ALPHANUMERIC_WITH_DASH_REGEXP}
                        value={formValues.poNumber!}
                        onChange={(value) => handleInputChange("poNumber", value)}
                    />
                </FormField>
                <FormField
                    errorText={vendorError}
                    data-testid="field-vendor"
                    label={
                        <span>
                            {getString(`${stringPath}.vendor`) + " TEST"}
                            <i>{getString(`${stringPath}.requiredField`)}</i>
                        </span>}>
                    <Input
                        type="text"
                        onChange={({ detail }) => {
                            handleInputChange("vendor", detail.value);
                        }}
                        value={formValues.vendor!}
                    />
                </FormField>
                <FormField data-testid="field-carrier" label={getString(`${stringPath}.carrier`)}>
                    <Input
                        type="text"
                        onChange={({ detail }) => {
                            handleInputChange("carrier", detail.value);
                        }}
                        value={formValues.carrier!}
                    />
                </FormField>
            </ColumnLayout>

            <ColumnLayout columns={4}>
                <FormField data-testid="field-trackingNumber" label={getString(`${stringPath}.trackingNumber`)}>
                    <Input
                        type="text"
                        onChange={({ detail }) => {detail.value
                            handleInputChange("trackingNumber", detail.value);
                        }}
                        value={formValues.trackingNumber!}
                    />
                </FormField>
                <FormField data-testid="field-deliveryDate" label={getString(`${stringPath}.deliveryDate`)}>
                    <DatePicker
                        onChange={({ detail }) => handleInputChange("deliveryDate", moment(detail.value).toDate())}
                        value={moment(formValues.deliveryDate?.toISOString()).format("YYYY-MM-DD")}
                        placeholder="YYYY/MM/DD"
                    />
                </FormField>
                <FormField data-testid="field-quantityDelivered" label={getString(`${stringPath}.quantityDelivered`)}>
                    <Input
                        type="number"
                        onChange={({ detail }) => {
                            const value = detail.value === '' ? 0 : parseInt(detail.value, 10);
                            if (value >= 0) {
                                handleInputChange("quantityDelivered", Number(value));
                            }
                        }}
                        value={formValues.quantityDelivered!.toString()}
                    />
                </FormField>
            </ColumnLayout>
        </Modal>
    );
};
