import { SplitPanel } from '@amzn/awsui-components-react/polaris';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { ManageOrdersTable } from '../../components/ManageOrders';
import SideNavigationMenu from '../../common/navigation/SideNavigationMenu';
import NavigationBreadcrumbs from '../../common/navigation/NavigationBreadcrumbs';
import { Flashbar } from '@amzn/awsui-components-react';

import { useManageOrdersTable } from 'components/ManageOrders/useManageOrdersTable';
import { useFlashBarItems } from '../../common/UseFlashBarItems/useFlashBarItems';
import Paths from '../../common/paths/paths';
import { useState } from 'react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { OrderItemsTable } from 'components/ManageOrders/OrderItemsTable/OrderItemsTable';
import { OrderItem } from '@amzn/ito-client';

const breadcrumbs = {
  items: [
    { text: Paths.Admin.name, href: Paths.Admin.url },
    { text: Paths.manageOrders.name, href: Paths.manageOrders.url }
  ]
};

const ManageOrdersPage = () => {
  const flashBarItemsHook = useFlashBarItems();
  const useManageOrdersTableHook = useManageOrdersTable();
  const [navigationOpen, setNavigationOpen] = useState(false);

  const [showSplitPanel, setSplitPanel] = useState(false);
  const [orderSelected, setOrderSelected] = useState("");
  const [orderItems, setOrderItems] = useState<OrderItem[] | undefined>([]);

  const updateOrderSelected = (showSplitPanel: boolean, orderSelected: string, orderItems: OrderItem[] | undefined) => {
    setSplitPanel(showSplitPanel);
    setOrderSelected(orderSelected);
    setOrderItems(orderItems);
  };

  /* istanbul ignore next */
  const onSplitPanelToggle: NonCancelableEventHandler = () => {
    setSplitPanel(false);
  };

  return (
    <AppLayout
      breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
      navigation={<SideNavigationMenu />}
      navigationOpen={navigationOpen}
      notifications={<Flashbar items={flashBarItemsHook.flashBarItems} stackItems />}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
      toolsHide={true}
      splitPanelOpen={showSplitPanel}
      onSplitPanelToggle={onSplitPanelToggle}
      splitPanel={
        <SplitPanel 
          closeBehavior="hide" 
          hidePreferencesButton={true} 
          header={orderSelected}
          data-testid="orders-split-panel"
        >
          <OrderItemsTable
            items={orderItems}
          />
        </SplitPanel>
      }
      content={
      <ManageOrdersTable
        updateOrderSelected={updateOrderSelected}
        useFlashBarItemsActions={flashBarItemsHook.actions}
        useManageOrdersTableHook={useManageOrdersTableHook}
      />
      }
    />
  );
};

export default ManageOrdersPage;
