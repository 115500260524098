import { Box, Button, Header, Icon, Modal, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useEffect } from 'react';
import { useAssignOrderModal } from './useAssignOrderModal';
import { Order, UpdateOrderStatusBatchCommandOutput } from '@amzn/ito-client';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { useOrderEdit } from 'views/OrdersDetailsPage/useOrderEdit';

export interface AssignOrderModalProps {
  onDismiss: (shouldRefreshTable?: boolean) => void;
  orders: Order[];
  flashBarItemsActions?: useFlashBarItemsActions;
  procurementMembersSelectOptions?: SelectProps.Option[];
}

export const AssignOrderModal: FunctionComponent<AssignOrderModalProps> = (props: AssignOrderModalProps) => {
  const { actions, state: attributes } = useAssignOrderModal(props.orders);
  const { actions: editActions } = useOrderEdit(props.flashBarItemsActions!);

  /* istanbul ignore next */
  const onDismiss = () => {
    props.onDismiss(false);
  };

  /* istanbul ignore next */
  useEffect(() => {
    const prefix = props.orders.length > 1 ? 'Orders' : 'Order';
    if (attributes.error) {
      props.flashBarItemsActions?.addErrorFlashBarItem(`${prefix} assignation failed.`, attributes.error.message);
      props.onDismiss(false);
    } else if (attributes.data) {
      props.flashBarItemsActions?.addSuccessFlashBarItem(
        `${prefix} succesfully assigned to ${attributes.assignee.value}.`
      );
      
      // If assigning the orders was successful, flag the orders
      const dataAsOrders: Order[] = (attributes.data as any).orders ?? [attributes.data];
      const assignedOrdersId = dataAsOrders.map(order => order && order.orderId!);
      if (assignedOrdersId && assignedOrdersId.length > 0) {
        editActions.flagOrderWithFirstEdit(assignedOrdersId);
      }

      props.onDismiss(true);
    }
  }, [attributes.error, attributes.data]);

  /* istanbul ignore next */
  const buildFooter = () => {
    const disableFooter = attributes.isLoading === true;
    const displayAssignButton = !(attributes.error || attributes.data);

    return (
      <SpaceBetween direction="horizontal" size="xs">
        <Button data-testid="close-button" variant="link" onClick={onDismiss} disabled={disableFooter}>
          Close
        </Button>
        {displayAssignButton && (
          <Button
            data-testid="assign-order-button"
            variant="primary"
            onClick={actions.doAssingOrders}
            disabled={disableFooter}
            loading={disableFooter}
          >
            Assign
          </Button>
        )}
      </SpaceBetween>
    );
  };

  const footerElement = buildFooter();

  return (
    <Modal
      data-testid="assign-order-modal"
      onDismiss={onDismiss}
      visible={true}
      header={<Header variant="h2">Assign</Header>}
      footer={<Box float="right">{footerElement}</Box>}
    >
      <Select
        data-testid="assign-order-select"
        selectedOption={attributes.assignee}
        options={props.procurementMembersSelectOptions}
        filteringType="auto"
        onChange={actions.onAssignedChange}
      ></Select>
    </Modal>
  );
};
