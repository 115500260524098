import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { useMemo } from 'react';
import { MessageComponent } from './MessageComponent';
import { getString } from 'common/ui-string-labels/ui-strings-utils';
import { GetCommentsResult } from 'hooks';
const stringPath = "orderDetails.tabs.messages";
  
interface MessageComponentProps {
    comments: GetCommentsResult
}

export const MessageList = (props: MessageComponentProps) => {
    const renderContent = useMemo(() => {
        if (props.comments?.totalResults! > 0) {
            return props.comments?.comments?.map((comment) => <MessageComponent key={comment.commentId} comment={comment} />);
        }
        return (
            <Box textAlign="center" data-testid="no-comments-message">
                {getString(`${stringPath}.noMessages`)}
            </Box>
        );
    }, [props.comments]);

    return(
        <SpaceBetween size="xl">{renderContent}</SpaceBetween>
    )
};
  