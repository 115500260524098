import { Page } from "common/utils/config-models";
import { getString } from "../ui-string-labels/ui-strings-utils";

export default class Paths {
    // Manage orders page is being used as Home page

    static readonly Admin: Page = { name: "Admin", url: "/orders" };

    static readonly manageOrders: Page = { name: getString("manageOrders.table.headerTitle"), url: "/orders" };
    static readonly manageCatalog: Page = { name: getString("manageCatalog.table.headerTitle"), url: "/catalog" };
    static readonly createCatalog: Page = {  name: getString("createCatalog.formLabels.formHeader"), url: "/catalog/create" };
    static readonly editCatalog: Page = { name: getString("editCatalog.formHeader"), url: "/catalog/edit/:id" };
    static readonly orderDetails: Page = { name: getString("orderDetails.title"), url: "/orders/:id" };
    static readonly exports: Page = { name: getString("exports.table.headerTitle"), url: "/exports" };
    static readonly attachmentDownload: Page = { name: "Attachment Download", url: "/a/:id" };
    static readonly notFound: Page = { name: getString("notFound.title"), url: "/not-found" };
}
