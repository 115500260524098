import { Input, InputProps } from '@amzn/awsui-components-react';

interface ValidatedInputProps extends Omit<InputProps, 'onChange'> {
    value: string;
    onChange: (value: string) => void;
    regex: RegExp;
}

const ValidatedInput: React.FC<ValidatedInputProps> = ({ value, onChange, regex, ...inputProps }) => {
    return (
        <Input
            {...inputProps}
            value={value}
            onChange={({ detail }) => {
                if (regex.test(detail.value)) {
                    onChange(detail.value);
                }
            }}
        />
    );
};

export default ValidatedInput;
