import { Container, Grid, Header } from "@amzn/awsui-components-react";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { CatalogItemValues } from "interfaces/catalogSchema";

import CheckboxGroup from "../../../common/CheckboxGroup/CheckboxGroup";
import regionsData from "./regions.json";

interface AvailableRegionsFormProps {
    handleInputChange: (field: keyof CatalogItemValues, value: string[]) => void;
    selectedRegions: string[];
}

const AvailableRegionsForm = ({ handleInputChange, selectedRegions }: AvailableRegionsFormProps) => {
    /* istanbul ignore next */
    const handleRegionChange = (newRegions: string[]) => {
        handleInputChange("region_restrictions", newRegions);
    };

    return (
        <Container
            data-testid="available-regions-form"
            header={<Header variant="h2">{getString("createCatalog.formAvailableRegions.formHeader")}</Header>}
        >
            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 3 }, { colspan: 3 }]}>
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionEMEA")}
                    items={regionsData.regionsEMEA}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionEMEA")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionAmericas")}
                    items={regionsData.regionsAmericas}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionAmericas")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionAPAC")}
                    items={regionsData.regionsAPAC}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionAPAC")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionNorthAmerica")}
                    items={regionsData.regionsNorthAmerica}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionNorthAmerica")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
                <CheckboxGroup
                    label={getString("createCatalog.formAvailableRegions.regionVirtual")}
                    items={regionsData.regionsVirtual}
                    selectAllLabel={getString("createCatalog.formAvailableRegions.regionVirtual")}
                    selectedRegions={selectedRegions}
                    handleRegionChange={handleRegionChange}
                />
            </Grid>
        </Container>
    );
};

export default AvailableRegionsForm;
