import {
    Box,
} from '@amzn/awsui-components-react';
import { Order, OrderItem } from '@amzn/ito-client';

import ItemsRequestedTable from './ItemsRequestedTab/ItemsRequestedTable';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { useOrderEditActions, useOrderEditItems } from 'views/OrdersDetailsPage/useOrderEdit';

interface ItemsRequestedProps {
    order: Order,
    items: OrderItem[],
    selectedItems: OrderItem[];
    useOrderEditItems: useOrderEditItems;
    useOrderActions: useOrderEditActions;
    flashBarItemsActions: useFlashBarItemsActions,
}

export const ItemsRequestedTab = (props: ItemsRequestedProps) => {
    return (
        <Box padding={{ top: "m", left: "xs" }}>
            <ItemsRequestedTable
                {...props}
                data-test-id="items-requested-table"
            />
        </Box>
    );
}