import { Box, Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { getString } from '../../../common/ui-string-labels/ui-strings-utils';

import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { AssignOrderModal } from "../AssignOrderModal";
import { useState } from 'react';
import { SelectionState } from '../useManageOrdersTable';
import { Order } from '@amzn/ito-client';


export const ManageOrdersTableHeader = (props: {
  totalResults: number;
  selectedItems: Order[];
  procurementMembersSelectOptions: SelectProps.Option[];
  refreshData: (clearPreviousData: boolean) => void;
  useFlashBarItemsActions: useFlashBarItemsActions;
}): JSX.Element => {

  /* istanbul ignore next */
  const [showAssignModal, setShowAssignModal] = useState(false);
  const { useFlashBarItemsActions, procurementMembersSelectOptions } = props;

  let selectionState: SelectionState;

  /* istanbul ignore next */
  if (props.selectedItems.length == 0) {
      selectionState = SelectionState.NONE;
  } else if (props.selectedItems.length == 1) {
      selectionState = SelectionState.ONE;
  } else {
      selectionState = SelectionState.MULTIPLE;
  }

  const onButtonClicked = () => {
    console.log("Button clicked");
  };

  /* istanbul ignore next */
  const onAssignOrders = () => {
    setShowAssignModal(true);
};

  /* istanbul ignore next */
  const dismissAssignModal = (shouldRefreshTable: boolean = false) => {
    if (shouldRefreshTable) {
        props.refreshData(true);
    }
    setShowAssignModal(false);
  };

  /* istanbul ignore next */
  const disableAssigneeButton = (): boolean => {
    if (selectionState == SelectionState.NONE) return true;

    return false;
  };

  return (
    <SpaceBetween size="m">
      <Header
        data-testid="manage-orders-table-header"
        variant="awsui-h1-sticky"
        counter={`(${props.totalResults})`}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button data-testid="open-assign-modal-button" variant="normal" disabled={disableAssigneeButton()} onClick={onAssignOrders}>
                {getString('manageOrders.table.assignOrderButton')}
            </Button>
          </SpaceBetween>
        }
      >
        {getString("manageOrders.table.headerTitle")}
      </Header>
      {showAssignModal && (
          <AssignOrderModal
            onDismiss={dismissAssignModal}
            orders={props.selectedItems}
            flashBarItemsActions={useFlashBarItemsActions!}
            procurementMembersSelectOptions={procurementMembersSelectOptions}
          />
      )}
      <Box color="text-status-inactive">{getString('manageOrders.table.headerDescription')}</Box>
    </SpaceBetween>
  );
};
