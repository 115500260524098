import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { useState } from "react";

import NavigationBreadcrumbs from "../../common/navigation/NavigationBreadcrumbs";
import SideNavigationMenu from "../../common/navigation/SideNavigationMenu";
import Paths from "../../common/paths/paths";
import CreateCatalogForm from "../../components/CreateCatalogForm/CreateCatalogForm";

const breadcrumbs = {
    items: [
        { text: Paths.Admin.name, href: Paths.Admin.url },
        { text: Paths.manageCatalog.name, href: Paths.manageCatalog.url },
        { text: Paths.createCatalog.name, href: Paths.createCatalog.url },
    ],
};

const CreateCatalogPage = () => {
    const [navigationOpen, setNavigationOpen] = useState(false);

    return (
        <AppLayout
            content={<CreateCatalogForm />}
            contentType={"form"}
            breadcrumbs={<NavigationBreadcrumbs items={breadcrumbs.items} />}
            navigation={<SideNavigationMenu />}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
        />
    );
};

export default CreateCatalogPage;
