import { ITO } from "@amzn/ito-client";
import { SentryFetchHttpHandler } from "@amzn/sentry-fetch-http-handler";
import { API_BASE_URL } from "configuration/config";

export function getITOClient(): { client: ITO; requestHandler: SentryFetchHttpHandler } {
    const requestHandler = new SentryFetchHttpHandler();
    return {
        client: new ITO({
            endpoint: `${API_BASE_URL}/ito`,
            region: "*",
            credentials: { accessKeyId: "", secretAccessKey: "" },
            requestHandler: requestHandler,
        }),
        requestHandler: requestHandler,
    };
}