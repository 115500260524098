import { Order, UpdateOneOrderStatus } from "@amzn/ito-client";
import useInput from "hooks/useInput";
import jp from 'jsonpath';
import { useEffect, useState } from "react";
import { OrdersService } from "services/orders-service";
import { buildingsData } from "data";

export interface EditableOrderDetails {
    status: string | undefined,
    assignedTo: string | undefined,
    "details.costCenter": string | undefined,
    "details.financialCode": string | undefined,
    "details.projectCode": string | undefined,
    "details.glCode": string | undefined,
    "details.locationGlCode": string | undefined,
    "details.businessJustification": string | undefined,
    "details.deliverTo.building": string | undefined,
    "details.deliverTo.city": string | undefined,
    "details.deliverTo.region": string | undefined,
    "details.deliverTo.country": string | undefined
}

export const useOrderSummaryForm = (receivedOrder: Order) => {

    // Fill initial values
    const order = receivedOrder;
    const details = order.details ? order.details as any : {};

    const [procurementMembers, setProcurementMembers] = useState<string[]>([]);

    const handleBuildingChange = (building: string) => {
        const selectedBuilding = buildingsData.find(b => b.building === building);
        if (selectedBuilding) {
            setFormValuesBulk({
                "details.deliverTo.city": selectedBuilding.city,
                "details.deliverTo.region": selectedBuilding.region,
                "details.deliverTo.country": selectedBuilding.country
            });
        }
    };

    const { formValues, handleInputChange, setFormValuesBulk } = useInput<EditableOrderDetails>({
        status: order.status,
        assignedTo: order.assignedTo,
        "details.costCenter": details["costCenter"],
        "details.projectCode": details["projectCode"],
        "details.financialCode": details["financialCode"],
        "details.glCode": details["glCode"],
        "details.locationGlCode": details["locationGlCode"],
        "details.businessJustification": details["businessJustification"],
        "details.deliverTo.building": getValueByPath(details, "deliverTo.building"),
        "details.deliverTo.city": getValueByPath(details, "deliverTo.city"),
        "details.deliverTo.region": getValueByPath(details, "deliverTo.region"),
        "details.deliverTo.country": getValueByPath(details, "deliverTo.country")
    }, { buildingChange: handleBuildingChange });

    // Modified validateForm method that uses validateRequiredFields
    const getUpdatedFields = (formValues: EditableOrderDetails, firstEdit?: boolean): UpdateOneOrderStatus => {
        let request: UpdateOneOrderStatus = { orderId: order.orderId };

        // Always include details.deliverTo fields in the request
        const deliverToFields = [
            "details.deliverTo.building",
            "details.deliverTo.city",
            "details.deliverTo.region",
            "details.deliverTo.country"
        ];
        
        // Get only updated fields
        const updatedFieldsPath = Object.keys(formValues).map((key) => {
            const newValue = formValues[key as keyof EditableOrderDetails];

            // Value was modified or it's a deliverTo field
            if (newValue !== getValueByPath(order, key) || deliverToFields.includes(key)) {
                return key;
            }
        });

        // Transform it to a property in the request object
        updatedFieldsPath.forEach((fieldPath) => {
            if (fieldPath) {
                setNestedProperty(request, fieldPath, formValues[fieldPath as keyof EditableOrderDetails]);
            }
        });

        // Flag the order on first edit
        if (firstEdit) {
            setNestedProperty(request, "details.firstEdit", "ham");
        }
        return request;
    };

    const getProcurementMembers = async () => {
        // TO DO: Cache the request from the Manage Orders table
        const members = await OrdersService.instance.getProcurementHardwareTeamMembers();
        setProcurementMembers(members);
    };

    useEffect(() => {
        getProcurementMembers();
    }, []);

    return {
        formValues,
        handleInputChange,
        getUpdatedFields,
        procurementMembers,
        handleBuildingChange
    };
};

function getValueByPath<T>(obj: T, path: string): any {
    const value = jp.value(obj, path as string);
    return value ?? undefined;
}

/**
 * Based on a path like "field.nested1.nested2" returns an object like { field: { nested1: { nested2: "value" } } }
 */
export function setNestedProperty(obj: any, path: string, value: any): void {
    const keys = path.split('.');
    keys.reduce((acc, curr, index) => {
        if (index === keys.length - 1) {
            acc[curr] = value;
        } else {
            if (!acc[curr]) {
                acc[curr] = {};
            }
            return acc[curr];
        }
        return acc;
    }, obj);
}