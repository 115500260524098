import { ColumnLayout, Container, FormField, Header, Input } from "@amzn/awsui-components-react";
import FormFieldWithSelect from "common/FormFieldWithSelect/FormFieldWithSelect";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { CatalogItemValues } from "interfaces/catalogSchema";

interface TaxonomyFormProps {
    catalogItemValues: CatalogItemValues;
    handleInputChange: (field: keyof CatalogItemValues, value: string) => void;
    errors: {
        subtypeError: string;
        manufacturerError: string;
        shortNameError: string;
        skuError: string;
    };
    isEditMode?: boolean;
}

const subtypeOptions = [
    { label: "Local", value: "local" },
    { label: "Hybrid", value: "hybrid" },
    { label: "Cloud", value: "cloud" },
];

const TaxonomyForm = ({ catalogItemValues, handleInputChange, errors, isEditMode }: TaxonomyFormProps) => {
    const taxonomyIdPreview = `atn::software/${catalogItemValues.subtype}/${catalogItemValues.manufacturer}/${catalogItemValues.short_name}/${catalogItemValues.sku}`;

    /* istanbul ignore next */
    return (
        <Container
            data-testid="taxonomy-form"
            header={
                <Header description={`Taxonomy ID Preview: ${taxonomyIdPreview}`} variant="h2">
                    {getString("createCatalog.formTaxonomy.formHeader")}
                </Header>
            }
        >
            <ColumnLayout columns={4}>
                <FormFieldWithSelect
                    dataCy="product-subtype-select"
                    ariaRequired
                    selectedValue={catalogItemValues.subtype}
                    disabled={isEditMode}
                    error={errors.subtypeError}
                    label={getString("createCatalog.formTaxonomy.inputSubType")}
                    options={subtypeOptions}
                    onSelectionChange={(value) => handleInputChange("subtype", value)}
                />
                <FormField
                    errorText={errors.manufacturerError}
                    data-testid="formfield-test"
                    stretch
                    label={getString("createCatalog.formTaxonomy.inputVendor")}
                >
                    <Input
                        data-cy="product-manufacturer-input"
                        ariaRequired
                        disabled={isEditMode}
                        onChange={({ detail }) => handleInputChange("manufacturer", detail.value)}
                        value={catalogItemValues.manufacturer}
                    />
                </FormField>
                <FormField
                    errorText={errors.shortNameError}
                    data-testid="formfield-test"
                    stretch
                    label={getString("createCatalog.formTaxonomy.inputShortName")}
                >
                    <Input
                        data-cy="product-short-name-input"
                        ariaRequired
                        disabled={isEditMode}
                        onChange={({ detail }) => handleInputChange("short_name", detail.value)}
                        value={catalogItemValues.short_name}
                    />
                </FormField>
                <FormField
                    errorText={errors.skuError}
                    data-testid="formfield-test"
                    stretch
                    label={getString("createCatalog.formTaxonomy.inputSku")}
                >
                    <Input
                        data-cy="product-sku-input"
                        ariaRequired
                        disabled={isEditMode}
                        onChange={({ detail }) => handleInputChange("sku", detail.value)}
                        value={catalogItemValues.sku}
                    />
                </FormField>
            </ColumnLayout>
        </Container>
    );
};

export default TaxonomyForm;
