import React, { useEffect } from 'react';

import Box from '@amzn/awsui-components-react/polaris/box';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import { Attachment } from '@amzn/ito-client';

import { getTimestampString } from 'common';
import { Button } from '@amzn/awsui-components-react';
import { useGetItemAttachment } from 'hooks';

interface AttachmentProps {
  attachment: Attachment
}

export const AttachmentFile = (props: AttachmentProps) => {
  const attachment = props.attachment;
  
  const {
    data,
    doGetItemAttachment
  } = useGetItemAttachment();

  async function downloadFile() {
    doGetItemAttachment({
      attachmentId: props.attachment.attachmentId ?? "",
    })
  }

  useEffect(() => {
    if (data?.preSignedUrl) {
      window.open(data?.preSignedUrl, '_blank');
    }
  }, [data])

  return (
    <Box data-testid="attachment-file">
      <SpaceBetween direction='horizontal' size='xs'>
        <Icon variant="link" name="file" />
        <Box>
          {attachment.fileName && (
            <Box data-testid="attachment-file-filename" key={"styles.fileName"}>
              <span title={attachment.fileName}>{attachment.fileName}</span>
            </Box>
          )}
          <SpaceBetween direction="vertical" size="xxxs">
            {attachment.uploadedStamp?.by && (
              <Box fontSize="body-s" color="text-body-secondary">
                {attachment.uploadedStamp?.by}
              </Box>
            )}
            {attachment.uploadedStamp?.on && (
              <Box fontSize="body-s" color="text-body-secondary">
                {getTimestampString(attachment.uploadedStamp?.on)}
              </Box>
            )}
              <Button 
                data-testid="attachment-download-button"
                variant='inline-link'
                onClick={downloadFile}
              >
                Download
              </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </Box>
  )
}