import { useState } from "react";

/**
 * Custom hook for handling form inputs.
 *
 * @template T The type of the form values object.
 * @param {T} initialState The initial state of the form values.
 * @param {object} [specialHandlers] Optional object containing special handlers keyed by a flag.
 * @returns An object containing the form values and a function to update them.
 */
export const useInput = <T extends Record<string, any>>(initialState: T, specialHandlers?: { [key: string]: (value: any) => void }) => {
    // State to store the current form values
    const [formValues, setFormValues] = useState<T>(initialState);

    /**
     * Function to handle changes in form inputs.
     *
     * @param {keyof T} field The field name whose value needs to be updated.
     * @param {any} value The new value of the input field.
     * @param {string} [flag] Optional flag to trigger special handling.
     */
    const handleInputChange = (field: keyof T, value: any, flag?: string) => {
        setFormValues((prev) => ({ ...prev, [field]: value }));
        
        // Check if there is a special handler for the given flag
        if (flag && specialHandlers && specialHandlers[flag]) {
            specialHandlers[flag](value);
        }
    };

    /**
     * Updates multiple catalog items values at once based on a provided object.
     *
     * @param {Partial<T>} valuesObj An object containing fields and their values to be updated.
     */
    const setFormValuesBulk = (valuesObj: Partial<T>) => {
        setFormValues((prev) => ({
            ...prev,
            ...valuesObj,
        }));
    };

    // Return the form values, input change handler and setCatalogItemValuesBulk
    return {
        formValues: formValues,
        handleInputChange,
        setFormValuesBulk: setFormValuesBulk,
    };
};

export default useInput;
