import { Box, Button, Header, Input, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import { getString } from "common";
import { MessageComponent } from "../Messages/MessageComponent";
import { useEffect, useMemo, useState } from "react";
import { useGetComments, usePostComment } from "hooks";
import { CSRFService } from "services/csrf-service";
import { OrderItem } from "@amzn/ito-client";
import { debounce } from "lodash";
import { MessageList } from "../Messages/MessagesList";
import { useOrderEditActions } from "views/OrdersDetailsPage/useOrderEdit";

const stringPath = "orderDetails.tabs.itemsRequested.lineCommentModal";
const FIVE_MINUTES_IN_MS = 300000;
const HALF_SECOND_IN_MS = 500;

export interface CommentsModalProps {
    items: OrderItem[];
    showModal: boolean;
    showComments: boolean;
    useOrderEditActions: useOrderEditActions,
    onDismiss: () => void;
}

export const LineCommentModal = (props: CommentsModalProps) => {
    const { showModal, onDismiss } = props;
    const [inputComment, setInputComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { response, error: errorPostComment, doPostComment, isLoading: isLoadingPost } = usePostComment();
    const { comments, isLoading: isLoadingComments, doGetComments } = useGetComments(undefined);

    // Auto refresh
    useEffect(() => {
        if (props.showComments == true) {
            const interval = setInterval(() => {
                doGetComments({
                    taxonomyId: props.items[0].itemId!,
                    fromAutoRefresh: true,
                    filters: { },
                });
            }, FIVE_MINUTES_IN_MS);
            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (response && !errorPostComment) {
            // If adding line item comment was successful, flag the order
            props.useOrderEditActions.flagOrderWithFirstEdit();
        }  
        if (props.showComments == true) {
            doGetComments({ taxonomyId: props.items[0].itemId!, filters: {  } });
        }
    }, [response, errorPostComment]);

    useEffect(() => {
        setIsLoading(isLoadingComments || isLoadingPost);
    }, [isLoadingComments, isLoadingPost]);

    const debouncedSendComment = debounce(sendComment, HALF_SECOND_IN_MS);
    function handleSendComment() {
        setIsLoading(true);
        setInputComment("");
        debouncedSendComment();
    }

    async function sendComment() {
        const csrfService = new CSRFService();
        const csrfToken = await csrfService.getToken();
        for (const item of props.items) {
            doPostComment(item.itemId!, inputComment, csrfToken, []);
        }
    }

    const footerElement = (
        <>
            <Input
                onChange={(event) => setInputComment(event.detail.value)}
                value={inputComment}
                disabled={isLoading}
                data-testid="line-comment-input"
            />
            <Box float="right" padding="xxs">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onDismiss}>
                        {getString(`${stringPath}.cancelButton`)}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSendComment}
                        loading={isLoading}
                        disabled={isLoading || !inputComment}
                        data-testid="line-send-comment"
                    >
                        {getString(`${stringPath}.sendButton`)}
                    </Button>
                </SpaceBetween>
            </Box>
        </>
    );

    function renderHeader() {
        if (props.showComments) {
            if (
                props.items[0].itemInformation &&
                typeof props.items[0].itemInformation === "object" &&
                "name" in props.items[0].itemInformation
            ) {
                return (
                    <Header variant="h2">
                        {getString(`${stringPath}.header`) + ": " + props.items[0].itemInformation.name}
                    </Header>
                );
            }
        }
        return <Header variant="h2">{getString(`${stringPath}.header`)}</Header>;
    }

    return (
        <Modal
            data-testid="line-comment-modal"
            onDismiss={onDismiss}
            visible={showModal}
            header={renderHeader()}
            footer={footerElement}
            size="large"
        >
            {props.showComments==true ? 
                <SpaceBetween size="xs">
                    <SpaceBetween size="xl">
                        <MessageList comments={comments}/>
                    </SpaceBetween>
                </SpaceBetween>
                : null
            }
        </Modal>
    );
};
