import {
  Container,
  Header,
  Spinner,
  Table
} from '@amzn/awsui-components-react';
import { ColumnSetting } from 'common/utils/config-models';
import { FunctionComponent, useState } from 'react';
import EmptyTableBox from '../../../common/EmptyTableBox/EmptyTableBox';
import { getString } from '../../../common/ui-string-labels/ui-strings-utils';
import {
  buildColumnDefinitions
} from '../../../common/utils';

import '../ManageOrdersTable.scss';
import ordersTableConfig from '../order-items.table-config.json';
import { OrderItem } from '@amzn/ito-client';
const columns: ColumnSetting[] = ordersTableConfig.columns;

export interface OrdersItemsTableProps {
  fileId?: string;
  fileName?: string;
  items?: OrderItem[] | null;
}

export const OrderItemsTable: FunctionComponent<OrdersItemsTableProps> = (props: OrdersItemsTableProps) => {
  // Actions
  const [loading, ] = useState(false);

  const currentColumnDefinitions = buildColumnDefinitions(columns);

  if (loading) {
    return (
      <Container>
        <Header variant="h2" data-testid="download-loading">
          {getString('manageOrders.table.downloadloading')}
        </Header>
        <Spinner size="large" />
      </Container>
    );
  }

  return (
    <Table
      data-testid="order-items-table"
      empty={<EmptyTableBox />}
      header={
        <Header>{getString('orderItems.table.headerTitle')}</Header>
      }
      columnDefinitions={currentColumnDefinitions}
      loadingText={getString('common.loading')}
      items={props.items!}
      resizableColumns={true}
      wrapLines
    />
  );
};
