import { useEffect, useState } from 'react';

import { PropertyFilterProps, TableProps } from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';

import { SYSTEM_FULFILL_NAME } from 'configuration/config';
import { OrdersService } from '../../services/orders-service';
import { Order } from '@amzn/ito-client';

export enum SelectionState {
  MULTIPLE,
  NONE,
  ONE
}

interface useManagerOrdersTableAttributes {
  procurementMembers: string[];
  procurementMembersSystem: string[];
  selectedItems: Order[];
  currentFilteringQuery: PropertyFilterProps.Query;
}

interface useManagerOrdersTableActions {
  onPropertyFilterChange: NonCancelableEventHandler<PropertyFilterProps.Query>;
  onSelectionChangeHandler: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>>;

  /**
   * Refresh the attributes managed by the hook
   */
  refreshAttributes: () => void;

  setSelectedItems: React.Dispatch<React.SetStateAction<Order[]>>;
}


export interface useManagerOrdersTableResult {
  attributes: useManagerOrdersTableAttributes;
  actions: useManagerOrdersTableActions;
}

export function useManageOrdersTable(): useManagerOrdersTableResult {
  const [selectedItems, setSelectedItems] = useState<
  Order[]>([]);
  const [procurementMembers, setProcurementMembers] = useState<string[]>([]);
  const [procurementMembersSystem, setProcurementMembersSystem] = useState<string[]>([]);
  
  // Checks for specific order id in the query params
  let orderIdToShow: string | null = null;

  // Filters and pagination
  const [currentFilteringQuery, setFilteringQuery] = useState<PropertyFilterProps.Query>({
    tokens: orderIdToShow
      ? [
          {
            operator: '=',
            value: orderIdToShow,
            propertyKey: 'orderId'
          }
        ]
      : [],
    operation: 'and'
  });

  const getProcurementMembers = async () => {
    const members = await OrdersService.instance.getProcurementHardwareTeamMembers();
    setProcurementMembers(members);
    setProcurementMembersSystem([...members, SYSTEM_FULFILL_NAME]);
  };

  useEffect(() => {
    getProcurementMembers();
  }, []);

  const onSelectionChangeHandler: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>> = e => {
    setSelectedItems!(e.detail.selectedItems);
  };

  // Filtering Events
  /* istanbul ignore next */
  const onPropertyFilterChange: NonCancelableEventHandler<PropertyFilterProps.Query> = e => {
    setFilteringQuery(e.detail);
  };

  const refreshAttributes = () => {
    setSelectedItems([]);
  };

  return {
    attributes: {
      selectedItems,
      procurementMembers,
      procurementMembersSystem,
      currentFilteringQuery
    },
    actions: {
      onPropertyFilterChange,
      onSelectionChangeHandler,
      refreshAttributes,
      setSelectedItems
    }
  };
}
