import {
    ColumnLayout,
    SpaceBetween,
} from '@amzn/awsui-components-react';

interface OrderDetailsSectionProps {
    columnsWithRows: string[][],
    renderedFormFields: { [id: string]: JSX.Element } | undefined;
}

export const ColumnFormSection = (props: OrderDetailsSectionProps) => {

    // Renders rows (fields of the order)
    const displayFields = (names: string[]) => names.map((field, index) => {
        return (
            <div key={index}>{props.renderedFormFields![field]}</div>
        )
    });

    // Renders columns (space between)
    const displayColumns = () => props.columnsWithRows.map((fields, index) => {
        return (
            <SpaceBetween key={index} size="xs">
                {displayFields(fields)}
            </SpaceBetween>
        )
    });

    return (
        <ColumnLayout columns={props.columnsWithRows.length} variant="text-grid">
            {props.renderedFormFields && displayColumns()}
        </ColumnLayout>
    )
};

