import { Box, ColumnLayout, FormField, SpaceBetween } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";

import CheckboxGroupItem from "./CheckboxGroupItem";

// Props definition for the CheckboxGroup component.
export interface CheckboxGroupProps {
    label: string;
    items: string[];
    selectAllLabel: string;
    selectedRegions: string[];
    handleRegionChange: (newRegions: string[]) => void;
}

const CheckboxGroup = ({ items, selectAllLabel, selectedRegions, handleRegionChange }: CheckboxGroupProps) => {
    //Filters and orders the list of regions by prioritizing the selected ones
    const filterMyRegions = (allRegions: string[], myRegions: string[]) => {
        return [...allRegions.filter((item) => !items.includes(item)), ...myRegions];
    };
    // State to keep track of the currently selected items (checkboxes).
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        // Initialize the selectedItems based on selectedRegions prop
        const initialSelectedItems = selectedRegions.filter((item) => items.includes(item));
        setSelectedItems(initialSelectedItems);
    }, [items, selectedRegions]);

    // Handles changes to individual checkboxes, updating the selected items state and notifying the parent component
    const handleCheckboxChange = (id: string) => {
        const isCurrentlySelected = selectedItems.includes(id);
        let newSelectedItems;

        /* istanbul ignore next */
        if (isCurrentlySelected) {
            newSelectedItems = selectedItems.filter((item) => item !== id);
        } else {
            newSelectedItems = [...selectedItems, id];
        }
        setSelectedItems(newSelectedItems);
        // Update the parent component's state
        handleRegionChange(filterMyRegions(selectedRegions, newSelectedItems));
    };

    // Handler function for "Select All" checkbox change
    const handleSelectAllChange = () => {
        const allItemsSelected = selectedItems.length === items.length;
        const newSelectedItems: string[] = allItemsSelected ? [] : [...items];
        setSelectedItems(newSelectedItems);
        handleRegionChange(filterMyRegions(selectedRegions, newSelectedItems));
    };

    const renderCheckboxes = (labels: string[]) => {
        const checkboxes = labels.map((label) => (
            <CheckboxGroupItem
                key={label}
                id={label}
                label={label}
                checked={selectedItems.includes(label)}
                onChange={() => handleCheckboxChange(label)}
            />
        ));
        return checkboxes;
    };

    return (
        <FormField data-testid="formfield-test" stretch>
            <SpaceBetween size="xs">
                <ColumnLayout borders="horizontal" columns={1}>
                    {/* Rendering the "Select All" checkbox */}
                    <CheckboxGroupItem
                        key="select-all"
                        id="select-all"
                        label={<strong>{selectAllLabel}</strong>}
                        checked={selectedItems.length === items.length}
                        onChange={handleSelectAllChange}
                    />
                    {/* Placeholder Box component for layout purposes */}
                    <Box />
                </ColumnLayout>
                {/* Rendering individual checkboxes based on the provided items */}
                {renderCheckboxes(items)}
            </SpaceBetween>
        </FormField>
    );
};

export default CheckboxGroup;
