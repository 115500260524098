export interface TableConfig {
  columns: ColumnSetting[]
}

export interface ColumnSetting {
  id: string;
  header: string;
  type?: string;
  editType?: string;
  editConfig?: any;
  props?: any;
  selector: string | string[];
  sortable?: boolean;
  minWidth?: number;
  linkSetting?: LinkItemSetting;
  operators?: string[];
  defaultOperator?: string;
  visibleByDefault?: boolean;
  visible: VisibleOption | string;
}

export enum VisibleOption {
  "never" = "never",
  "always" = "always",
  "yes" = "yes",
  "no" = "no"
}
export interface LinkItemSetting {
  url: string;
  idSelector: string;
}

export interface Page {
  name: string,
  url: string,
}

export interface CurrentPage {
  index: number,
  /**
   * True if the query was a full reset to page 1
   * False if only moving between pages
   */
  isPageReset: boolean,
}