import Button, { ButtonProps } from "@amzn/awsui-components-react/polaris/button";
import { useNavigate } from "react-router-dom";

/**
 * Button used for navigation in internal paths
 * @param props
 * @returns
 */

const InternalButton = (props: ButtonProps) => {
    const navigate = useNavigate();

    /**
     * Prevents reloading the page and lets react router handle the redirect
     * @param event
     */
    const onFollowHandler = (event: CustomEvent<ButtonProps.FollowDetail>) => {
        if (event.detail.href) {
            event.preventDefault();
            navigate(event.detail.href!);
        }
    };

    return <Button {...props} onFollow={onFollowHandler} />;
};

export default InternalButton;
