import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";

export interface CheckboxGroupItemProps {
    name?: string;
    id: string;
    label?: any;
    checked: boolean;
    onChange: (id: string) => void;
}

const CheckboxGroupItem = (props: CheckboxGroupItemProps) => {
    return (
        <Checkbox onChange={({ detail }) => props.onChange(props.id)} name={props.name} checked={props.checked}>
            {props.label}
        </Checkbox>
    );
};

export default CheckboxGroupItem;
