import { useEffect, useMemo, useState } from 'react';
import {
    SpaceBetween,
    Table,
    Header,
    Button,
    Box,
    TableProps
} from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { Order, OrderItem, UpdateItemDetailsCommandInput } from '@amzn/ito-client';

import EmptyTableBox from 'common/EmptyTableBox/EmptyTableBox';
import { useFlashBarItemsActions } from 'common/UseFlashBarItems/useFlashBarItems';
import { ColumnSetting } from 'common/utils/config-models';
import { getString, buildColumnDefinitions } from 'common';

import standardOrdersTableConfig from "./standard-items-requested.table-config.json";
import nonStandardOrdersTableConfig from "./nonstandard-items-requested.table-config.json";
import { AddSubLineItemModal } from './AddSubLineItemModal';
import { LineCommentModal } from './CommentsModal';
import { useUpdateItemDetails } from 'hooks';
import { CSRFService } from 'services/csrf-service';
import { setNestedProperty } from '../OrderSummaryTab/useOrderSummaryForm';
import { useOrderEditActions, useOrderEditItems } from 'views/OrdersDetailsPage/useOrderEdit';

const standardColumns: ColumnSetting[] = standardOrdersTableConfig.columns;
const nonStandardColumns: ColumnSetting[] = nonStandardOrdersTableConfig.columns;
const stringPath = "orderDetails.tabs.itemsRequested";

export interface ItemsRequestedTableProps {
    order: Order,
    items: OrderItem[],
    selectedItems: OrderItem[],
    useOrderEditItems: useOrderEditItems,
    flashBarItemsActions: useFlashBarItemsActions,
    useOrderActions: useOrderEditActions,
}

const ItemsRequestedTable = (props: ItemsRequestedTableProps) => {

    // Build column definitions based on requestType
    const columnDefinitions = useMemo(() => {
        const columns = (props.order.details as any)["requestType"] === 'NonStandard' ? nonStandardColumns : standardColumns;
        return buildColumnDefinitions(columns, undefined, props.useOrderEditItems.canBeEdited);
    }, [(props.order.details as any)["requestType"]]);

    // Actions
    const { data, error, doUpdateItemDetailsAsync } = useUpdateItemDetails();
    const onSelectionChange: NonCancelableEventHandler<TableProps.SelectionChangeDetail<any>> = e => {
        props.useOrderActions.setSelectedLineItems(e.detail.selectedItems);
    };

    // Add Subline item Button
    const [showSubLineItemModal, setShowSubLineItemModal] = useState(false);
    const disableSubLineButton = props.selectedItems.length !== 1; // Only one is selected to add sub line item

    const onAddSubLineItemClick = () => {
        setShowSubLineItemModal(true);
    }
    const onAddSubLineItemDismiss = () => {
        setShowSubLineItemModal(false);
    };

    // Add Comment Button
    const [showLineCommentModal, setShowLineCommentModal] = useState(false);
    const disableLineCommentButton = props.selectedItems.length <= 0; // One or more are selected to allow bulk line comment

    const onAddLineCommentClick = () => {
        setShowLineCommentModal(true);
    }
    const onAddLineCommentDismiss = () => {
        setShowLineCommentModal(false);
    };

    // Update subline items
    useEffect(() => {
        if (props.selectedItems.length === 0) {
            props.useOrderActions.updateSubLineItemSelected(undefined, undefined);
        }
        else if (props.selectedItems.length >= 1 && props.selectedItems[props.selectedItems.length - 1]) {
            const lastLineItemSelected = props.selectedItems[props.selectedItems.length - 1];
            props.useOrderActions.updateSubLineItemSelected!(props.order, lastLineItemSelected);
        }
    }, [props.selectedItems]);

    // Reload order
    useEffect(() => {
        if (data && !error) {
            // If line item edit was successful, flag the order
            props.useOrderActions.flagOrderWithFirstEdit();

            props.useOrderActions.reloadOrder(props.order.orderId);
        }
        else if (error) {
            props.flashBarItemsActions.addErrorFlashBarItem(`Error updating the item. ${error.message}`);
        }
    }, [data, error]);

    useEffect(() => {
        const overDeliveredItems = props.items.some(item =>
            item.status !== 'CANCELLED' &&
            item.subLineItems?.some(subLineItem =>
                (subLineItem.quantityDelivered ?? 0) > (item.quantity ?? 0)
            )
        );
        if (overDeliveredItems) {
            props.flashBarItemsActions.addWarningFlashBarItem("One or more sub-line items have delivered quantities greater than the requested quantities.");
        } 
    }, [props.items]);

    const handleSubmit = async (currentItem: OrderItem, columnDefinition: any, newValue: any) => {
        const updateProps: UpdateItemDetailsCommandInput = {
            orderId: props.order.orderId,
            itemId: currentItem.itemId
        };

        // Remove "$.", "$.name" --> "name";
        const pathOfFieldToUpdate = columnDefinition.selector.slice(2) as keyof UpdateItemDetailsCommandInput;
        setNestedProperty(updateProps, pathOfFieldToUpdate, newValue);

        const csrfService = new CSRFService();
        const token = await csrfService.getToken();
        await doUpdateItemDetailsAsync(updateProps, token);
    }

    return (
        <Box>
            {showSubLineItemModal && (
                <AddSubLineItemModal
                    showModal={showSubLineItemModal}
                    order={props.order}
                    item={props.selectedItems[0]}
                    flashBarItemsActions={props.flashBarItemsActions}
                    useOrderEditActions={props.useOrderActions}
                    onDismiss={onAddSubLineItemDismiss}/>
            )}

            {showLineCommentModal && (
                <LineCommentModal
                    items={props.selectedItems}
                    showComments={props.selectedItems.length == 1}
                    showModal={showLineCommentModal}
                    useOrderEditActions={props.useOrderActions}
                    onDismiss={onAddLineCommentDismiss} />
            )}

            <Table
                data-testid="items-requested-table"
                submitEdit={handleSubmit}
                items={props.items}
                columnDefinitions={columnDefinitions}
                selectedItems={props.selectedItems}
                onSelectionChange={onSelectionChange}
                resizableColumns={true}
                empty={<EmptyTableBox />}
                loadingText="Loading resources"
                selectionType="multi"
                variant="embedded"
                wrapLines
                contentDensity="comfortable"
                header={
                    <Header
                        data-testid="items-requested-table-header"
                        variant="awsui-h1-sticky"
                        counter={`(${props.items.length})`}
                        description={getString(`${stringPath}.table.headerDescription`)}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                {!props.useOrderEditItems.isReadOnly && <Button
                                    data-testid="item-add-linecomment-button"
                                    onClick={onAddLineCommentClick}
                                    disabled={disableLineCommentButton}
                                >
                                    {props.selectedItems.length < 2 ? getString(`${stringPath}.table.viewCommentButton`) : getString(`${stringPath}.table.addLineCommentButton`)}
                                </Button>}
                                {props.useOrderEditItems.canBeEdited && <Button
                                    data-testid="item-add-sublineitem-button"
                                    variant="primary"
                                    onClick={onAddSubLineItemClick}
                                    disabled={disableSubLineButton}
                                >
                                    {getString(`${stringPath}.table.addSubLineItemButton`)}
                                </Button>}
                            </SpaceBetween>
                        }
                    >
                        {getString(`${stringPath}.table.headerTitle`)}
                    </Header>
                }
            />
        </Box>
    );
}

export default ItemsRequestedTable;